import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sessions-container" }
const _hoisted_2 = { class: "view-container" }
const _hoisted_3 = { class: "profile-container" }
const _hoisted_4 = { class: "flex-row" }
const _hoisted_5 = { class: "blocks-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Игра с ИИ ", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backward())),
              class: "green-btn-small"
            }, " Назад ")
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Выберите один из 10 уровней сложности искусственного интеллекта.", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.robotOpponentsTranslatedFiltered, (robot, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `robot_${robot.engine}`,
              class: _normalizeClass(["child-block child-block-blue", _ctx.getOpacityClass( index )]),
              onClick: ($event: any) => (_ctx.createGame( robot.id, robot.level ))
            }, [
              _createElementVNode("h3", null, _toDisplayString(robot.displayName), 1),
              (_ctx.isGameExist( robot.level ))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "non-played",
                    onClick: ($event: any) => (_ctx.goExistGame( robot.level ))
                  }, " Не доиграно ", 8, _hoisted_7))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(robot.level), 1)
            ], 10, _hoisted_6))
          }), 128))
        ])
      ])
    ])
  ]))
}