<template>
    <section>
        <div class="section-logo">
            <router-link id="playerBtn" :to="{ name: 'home' }">
                <img src="@/assets/images/home.svg" title="Домой"/>
            </router-link>

            <a :href="home_url">
                <img src="@/assets/images/tavria_logo_blue.svg" />
            </a>

            <div class="flex-section">
                <div id="modals"></div>
                <Modal v-model="isShowLogoutModal" :close="closeModalAndExit">
                    <div class="modal">
                        <p>
                            Точно что хотите выйти ?
                        </p>
                        <button @click="closeModalAndExit">
                            Да
                        </button>
                        <button @click="closeModal">
                            Нет
                        </button>
                    </div>
                </Modal>

                <PlayerInfo v-if="isUserLogin"></PlayerInfo>

                <div v-if="isUserLogin" class="nav-container">
                    <a class='nav-link' @click="showLogoutModal">
                        Выход
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PlayerInfo from '@/components/Partials/CurrPlayerInfo.vue';


export default defineComponent({
    name: 'ClubNavbar',

    components: { PlayerInfo },

    data() {
        return {
            home_url: process.env.VUE_APP_HOME_PATH,
            isShowLogoutModal: false
        }
    },


    computed: {
        ...mapGetters({
            isUserLogin: 'auth_app/IS_USER_LOGIN'
        })
    },


    methods: {
        ...mapActions({
            user_logout: 'auth_app/LOGOUT'
        }),

        showLogoutModal() {
            this.isShowLogoutModal = true;
        },

        closeModal() {
            this.isShowLogoutModal = false;
        },

        closeModalAndExit( _event: MouseEvent ) {
            this.isShowLogoutModal = false;
            this.user_logout().then( _ => {
                this.$router.push({ name: 'login' });
            });
        }
    }
});
</script>


<style scoped lang="scss">
@import "@/assets/css/app.scss";

.section-logo img {
    margin-left: 10px;
}
.section-logo {
    // background-color: #d7d7d7;
    height: 70px;
}
.title {
    color: $tavria-blue;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
}
.nav-link {
    margin-right: 10px;
    cursor: pointer;
}
.flex-section {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
</style>
