import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "table table-striped" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "enemy-name" }
const _hoisted_6 = { class: "enemy-name" }
const _hoisted_7 = { class: "num" }
const _hoisted_8 = { class: "enemy-name" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (( _ctx.fixedPlayerGames.length > 0 ))
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("h3", null, " Недоигранные партии ", -1)),
        _createElementVNode("table", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { class: "enemy-name" }, "Белые"),
              _createElementVNode("th", { class: "enemy-name" }, "Черные"),
              _createElementVNode("th", { class: "num" }, "Ходы"),
              _createElementVNode("th", { class: "enemy-name" }, "Последний ход")
            ])
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fixedPlayerGames, (game) => {
            return (_openBlock(), _createElementBlock("tbody", {
              class: "game-row",
              key: `game_${game.id}`
            }, [
              (game.isOffline)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_3, _cache[0] || (_cache[0] = [
                    _createElementVNode("td", { class: "enemy-name" }, [
                      _createElementVNode("span", { style: {"color":"red"} }, " Оффлайн игра (за информацией обратитесь к организатору турнира) ")
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("tr", _hoisted_4, [
                    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx._getTranslated( game.whitePlayer.displayName )), 1),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx._getTranslated( game.blackPlayer.displayName )), 1),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(game.movesCount), 1),
                    _createElementVNode("td", _hoisted_8, [
                      (game.yourTurn)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [
                            _createElementVNode("span", { class: "badge" }, " Ваш ход ", -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createVNode(_component_router_link, {
                        to: `/game/${game.id}`,
                        class: "button-small"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isFirstTournamentStage( game ))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Начать турнир "))
                            : (_ctx.isNextTournamentStage( game ))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Продолжить турнир "))
                              : (_openBlock(), _createElementBlock("span", _hoisted_12, " Играть "))
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ])
                  ]))
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}