<template>
    <section>
        <div class="sessions-container">
            <div class="view-container">
                <div class="profile-container">
                <div class="profile-row">
                    <h2>
                        {{ authenticationData.player.displayName }}
                    </h2>
                    <router-link :to="`/history`">
                        История
                    </router-link>
                    <router-link :to="`/games`">
                        Мои игры
                    </router-link>
                </div>
                    <p>Выберите тип игры и вперёд — к победе!</p>
                </div>  
                <div class="blocks-container">
                    <PlayerOpponentsRobotsTileBlock></PlayerOpponentsRobotsTileBlock>
                    <PlayerOpponentsHumansTileBlock></PlayerOpponentsHumansTileBlock>
                    <PlayerTournamentsTileBlock></PlayerTournamentsTileBlock>
                </div>
            </div>
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import GamesApprovesTable from "@/components/elements/PlayerGames/PlayerGamesApprovesTable.vue";
import PlayerTournamentsTileBlock from "@/components/elements/PlayerTournaments/PlayerTournamentsTileBlock.vue";
import PlayerOpponentsRobotsTileBlock from "@/components/elements/PlayerOpponents/PlayerOpponentsRobotsTileBlock.vue";
import PlayerOpponentsHumansTileBlock from "@/components/elements/PlayerOpponents/PlayerOpponentsHumansTileBlock.vue";


export default defineComponent({
    name: 'Home',
    components: { PlayerTournamentsTileBlock, PlayerOpponentsHumansTileBlock, PlayerOpponentsRobotsTileBlock, GamesApprovesTable },
    computed: {
        ...mapGetters({
            isUserLogin: 'auth_app/IS_USER_LOGIN',
            authenticationData: 'auth_app/AUTH_APP',
            playerHistory: 'player_profile/PLAYER_HISTORY'
        })
    },


    methods: {
        ...mapActions({
            loadPlayerProfile: 'player_profile/LOAD_PLAYER_PROFILE'
        })
    },


    created() {
        this.loadPlayerProfile();
    }
});
</script>
