
export function parseBoolFromString( strVar: string ) : boolean {
    let result = false;
    if ( strVar.toLowerCase() === 'true' ) {
        result = true;
    } else if ( strVar.toLowerCase() === '1' ) {
        result = true;
    } else if ( strVar.toLowerCase() === 'on' ) {
        result = true;
    }
    return result;
}



export interface RobotTranslateConfig {
    engine_id: number,
    engine_name: string,
    level: number,
    name: string
}
