import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "game-data gamer-name" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameStatusTimer = _resolveComponent("GameStatusTimer")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: '/player/' + _ctx.whitePlayer.id
      }, _toDisplayString(_ctx._getTranslated( _ctx.whitePlayer.displayName )), 9, _hoisted_2),
      _cache[0] || (_cache[0] = _createElementVNode("span", null, " против ", -1)),
      _createElementVNode("a", {
        href: '/player/' + _ctx.blackPlayer.id
      }, _toDisplayString(_ctx._getTranslated( _ctx.blackPlayer.displayName )), 9, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["game-data", _ctx.statusClass])
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.statusText), 1)
      ]),
      _createVNode(_component_GameStatusTimer, {
        withAIOpponent: _ctx.withAIOpponent,
        onGameStatusUpdated: _ctx._handleGameStatusStyle
      }, null, 8, ["withAIOpponent", "onGameStatusUpdated"])
    ], 2)
  ]))
}