
import { ActionContext, Module } from "vuex";
import Auth from "@development/irs-auth-interface/dist/auth";

import { LoginUserData } from "@/dto/requests";
import { conditionallyUrlJoin } from "@/common/functions";
import { IS_DEV_MODE_ENABLED } from "@/common/globals";



export interface AuthState {
    auth_state: Auth
}


const auth: Module<AuthState, any> = {
    namespaced: true,

    state: {
        auth_state: new Auth(
            conditionallyUrlJoin(process.env.VUE_APP_AUTH_PATH, process.env.VUE_APP_API_URL),
            "access_token",
            "refresh_token",
            "chess_was_logged",
            5,
            IS_DEV_MODE_ENABLED,
            'AUTH-CHANNEL'
        )
    },


    getters: {
        AUTH_STATE: ( state: AuthState ) : Auth => {
            return state.auth_state;
        },

        // ACCESS_TOKEN: ( state: AuthState ) => ( payload: SystemAuthRequestData ) : Promise<string|null> => {
        //     let access_token = null;
        //     /// Ниже временный костыль - нужно убрать !
        //     if ( IS_DEV_MODE_ENABLED ) {
        //         access_token = state.auth_state.userLogin( payload as any );
        //     } else {
        //         access_token = state.auth_state.wrapRefresh();
        //     }
        //     return access_token;
        // }
    },


    actions: {
        LOGIN: async ({ state }: ActionContext<AuthState, null>, payload: LoginUserData) : Promise<void> => {
            await state.auth_state.userLogin( payload as never as Record<string, never> )
                .catch( () => {
                    console.warn( 'USER LOGIN FAILED!' );
                });
        },

        REFRESH: async ({ state }: ActionContext<AuthState, null>) : Promise<void> => {
            await state.auth_state.forceRefresh();
        },

        LOGOUT: async ({ state }: ActionContext<AuthState, null>) : Promise<void> => {
            state.auth_state.userLogout();
        }
    }
};


export default auth;
