
import { Module } from "vuex";
import urlJoin from 'url-join';

import { axios_chess_api_client } from "@/common/axios";
import { GameApproveResponse, GameMainPartResponse, GameOpeningsResponse } from "@/dto/responses";



export function isGameFullyApproved(isReversedChecking: boolean, gameApprove: GameApproveResponse) : boolean {
    const isGameApprove = gameApprove.isWhitePlayerApprove && gameApprove.isBlackPlayerApprove;
    return isReversedChecking ? !isGameApprove : isGameApprove;  /// Если ссылка на игру существует в заявке, то она создана
}


export function isGameNeedYourApproved(playerId: number, gameApprove: GameApproveResponse) : boolean {
    let result = false;
    /// Если текущий игрок за "белых" и ещё не одобрил заявку со своей стороны
    if ( playerId === gameApprove.whitePlayer.id && gameApprove.isBlackPlayerApprove && !gameApprove.isWhitePlayerApprove) {
        result = true;
    } 
    /// Иначе, если текущий игрок за "чёрных" и ещё не одобрил заявку со своей стороны
    else if ( playerId === gameApprove.blackPlayer.id && gameApprove.isWhitePlayerApprove && !gameApprove.isBlackPlayerApprove ) {
        result = true;
    } 
    return result;
}


function _displayTime( sec: number ) : string {
    let time;
    if ( sec < 60 ) {
        const s = Math.floor( sec );
        time = `${s}s`;
    } else if ( sec < 3600 ) {
        const m = Math.floor( sec / 60 );
        const s = Math.floor( sec - 60 * m );
        time = `${m}min${s}s`;
    } else if ( sec < 48 * 3600 ) {
        const h = Math.floor( sec / 3600 );
        time = `${h} hours`;
    } else {
        const d = Math.floor( sec / (24 * 3600) );
        time = `${d} days`;
    }
    return time;
}



export interface UpdateGameMovesParams {
    gameId: number,
    apiPgnMove: string,
    backwardSteps: number
}


export interface GamesState {
    _games_opened: Array<GameMainPartResponse>,
    _player_approves: Array<any>,
    _currMoveIndex: number,
    _currGame: GameMainPartResponse,
    _currGameOpenings: Array<any>,
    _is_curr_game_loaded: boolean
}


const games: Module<GamesState, any> = {
    namespaced: true,

    state: {
        _currGame: {} as GameMainPartResponse,
        _currMoveIndex: 0,
        _games_opened: [],
        _player_approves: [],
        _currGameOpenings: [],
        _is_curr_game_loaded: false
    },


    getters: {
        IS_CURR_GAME_LOADED: ( state: GamesState ) : boolean => {
            return state._is_curr_game_loaded;
        },

        CURR_PLAYER_GAME: ( state: GamesState ) : GameMainPartResponse => {
            return state._currGame;
        },

        CURR_GAME_MOVE_INDEX: ( state: GamesState ) : number => {
            return state._currMoveIndex;
        },

        GAME_OPENINGS: ( state: GamesState ) : Array<any> => {
            return state._currGameOpenings;
        },

        PLAYER_GAMES: ( state: GamesState ) : Array<GameMainPartResponse> => {
            return state._games_opened;
        },

        // PLAYER_ONLINE_GAMES: ( state: GamesState ) : Array<GameMainPartResponse> => {
        //     return state._games_opened.filter( o => !o.isOffline );
        // },

        CURR_PLAYER_GAMES_MOVES_PGN: ( state: GamesState ) : Array<string> => {
            return ( state._currGame.moves || [] ).map( ( o: any ) => o.pgn );
        },

        PLAYER_APPROVALS_ALL: ( state: GamesState ) : Array<GameApproveResponse> => {
            return state._player_approves;
        },

        PLAYER_APPROVALS_OPENED: ( state: GamesState ) : Array<GameApproveResponse> => {
            return state._player_approves.filter( isGameFullyApproved.bind(null, true) );
        },

        PLAYER_APPROVALS_NEEDED_YOUR: (_state: GamesState, getters: any, _rootState: any, rootGetters: any) : Array<GameApproveResponse> => {
            const playerId = rootGetters['auth_app/AUTH_APP'].player.id;
            const playerApprovalsAll = getters['PLAYER_APPROVALS_ALL'];
            return playerApprovalsAll.filter( isGameNeedYourApproved.bind(null, playerId) );
        },

        FIXED_PLAYER_GAMES: (_state: GamesState, getters: any, _rootState: any, rootGetters: any) : Array<GameMainPartResponse> => {
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const results = getters['PLAYER_GAMES'].map( ( game: any ) => {
                if ( game.moves.length === 0 ) {
                    game.lastMove = '';
                } else {
                    const lastMoveTs = game.moves[ game.moves.length - 1 ].date * 1000;
                    const now = new Date().getTime();
                    const durationMs = now - lastMoveTs;
                    const durationString = _displayTime( durationMs / 1000 );
                    game.lastMove = `${durationString} ago`;
                }
                if ( authenticationData.player && game.nextPlayer.id === authenticationData.player.id ) {
                    game.yourTurn = true;
                }
                game.movesCount = 1 + Math.floor( ( game.moves.length - 1 ) / 2 );
                return game;
            });
            return results;
        }
    },


    actions: {
        LOAD_PLAYER_GAMES: async ({ commit, rootGetters }) : Promise<Array<GameMainPartResponse>> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const isOpen = true
            const url_params = new URLSearchParams({ 
                playerId: authenticationData.player.id.toString(), 
                open: isOpen.toString() 
            });
            const response = await axios_chess_api_client.get(
                urlJoin('game', 'search', '?' + url_params.toString()),
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_PLAYER_GAMES', response.data);
            return response.data;
        },

        LOAD_PLAYER_APPROVALS: async ({ commit, rootGetters }) : Promise<Array<GameApproveResponse>> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const response = await axios_chess_api_client.get(
                urlJoin('game', 'player', 'approve'),
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_PLAYER_APPROVES', response.data);
            return response.data;
        },

        LOAD_CURR_GAME_MOVES_OPENINGS: async ({ commit, rootGetters }, { moves } = {}) : Promise<Array<GameOpeningsResponse>> => {
            const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const url_params = new URLSearchParams({ 
                moves: moves 
            });
            const response = await axios_chess_api_client.get(
                urlJoin('game', 'openings', '?' + url_params.toString()),
                { 
                    headers: { 
                        'Authorization': access_token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_CURR_GAME_OPENINGS', response.data);
            return response.data;
        },

        LOAD_PLAYER_GAME: async ({ commit, rootGetters }, gameId: number) : Promise<GameMainPartResponse> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const response = await axios_chess_api_client.get(
                urlJoin('game', gameId.toString()),
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('UPDATE_PLAYER_GAME', response.data);
            return response.data;
        },

        LEAVE_PLAYER_GAME: async ({ commit, rootGetters }, gameId: number) : Promise<GameMainPartResponse> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const response = await axios_chess_api_client.post(
                urlJoin('game', gameId.toString(), 'resign'),
                null,
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('UPDATE_PLAYER_GAME', response.data);
            return response.data;
        },

        CREATE_PLAYER_GAME_APPROVE: async ({ commit, rootGetters }, opponentPlayerId: number) : Promise<GameApproveResponse> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const queryParams = new URLSearchParams({ 
                opponentPlayerId: opponentPlayerId.toString(),
            });
            const response = await axios_chess_api_client.post(
                urlJoin('game', 'player', '?' + queryParams.toString()),
                null,
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    }
                }
            );
            commit('UPDATE_PLAYER_APPROVE', response.data);
            return response.data;
        },

        APPROVE_PLAYER_GAME: async ({ commit, rootGetters }, approveId: number) : Promise<GameApproveResponse> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const response = await axios_chess_api_client.put(
                urlJoin('game', 'player', 'approve', approveId.toString()),
                null,
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('UPDATE_PLAYER_APPROVE', response.data);
            return response.data;
        },

        CREATE_ROBOT_GAME: async ({ commit, rootGetters }, opponentPlayerId: number) : Promise<GameMainPartResponse> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const queryParams = new URLSearchParams({ 
                opponentPlayerId: opponentPlayerId.toString(),
            });
            const response = await axios_chess_api_client.post(
                urlJoin('game', 'robot', '?' + queryParams.toString()),
                null,
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    }
                }
            );
            commit('UPDATE_ROBOT_GAME', response.data);
            return response.data;
        },

        UPDATE_PLAYER_GAME_MOVES: async ({ commit, rootGetters }, { gameId, apiPgnMove = '', backwardSteps = 0 }: UpdateGameMovesParams) : Promise<GameMainPartResponse> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const queryParams = new URLSearchParams({ 
                move: apiPgnMove || ''
            });
            if ( backwardSteps ) {
                queryParams.append('backwardSteps', backwardSteps.toString());
            }
            const response = await axios_chess_api_client.post(
                urlJoin('game', gameId.toString(), '?' + queryParams.toString()),
                null,
                { 
                    headers: { 
                        'Authorization': authenticationData.token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('UPDATE_PLAYER_GAME', response.data);
            return response.data;
        }
    },


    mutations: {
        SET_PLAYER_GAMES: (state: GamesState, objects: Array<GameMainPartResponse>) => {
            state._games_opened = objects;
        },

        SET_PLAYER_APPROVES: (state: GamesState, objects: Array<GameApproveResponse>) => {
            state._player_approves = objects;
        },

        SET_CURR_GAME_OPENINGS: (state: GamesState, objects: Array<any>) => {
            state._currGameOpenings = objects;
        },

        UPDATE_PLAYER_APPROVE: (_state: GamesState, object: GameApproveResponse) => {
            console.debug('UPDATE PLAYER APPROVE DATA: ', object);
        },

        UPDATE_PLAYER_GAME: (state: GamesState, object: GameMainPartResponse) => {
            state._currGame = object;
            state._is_curr_game_loaded = true;
        },

        UPDATE_ROBOT_GAME: (state: GamesState, object: GameMainPartResponse) => {
            state._currGame = object;
            state._is_curr_game_loaded = true;
        },

        UPDATE_CURR_GAME_MOVE_INDEX: (state: GamesState, moveIndex: number) => {
            state._currMoveIndex = moveIndex;
        }
    }
};


export default games;
