<template>
    <section>
            <div class="sessions-container">
                <div class="view-container">
                    <div class="profile-container">
                        <div class="flex-row">
                            <h2>
                                Мои игры
                            </h2>
                            <button @click="backward()" class="green-btn-small">
                                Назад
                            </button>
                        </div>
                    </div>  
                    <div class="blocks-container">
                        <GamesTable></GamesTable>
                        <GamesApprovesTable></GamesApprovesTable>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import GamesTable from "@/components/elements/PlayerGames/PlayerGamesTable.vue";
import GamesApprovesTable from "@/components/elements/PlayerGames/PlayerGamesApprovesTable.vue";


export default defineComponent({
    name: 'MyGames',

    components: { GamesTable, GamesApprovesTable },

    computed: {
        ...mapGetters({
            authenticationData: 'auth_app/AUTH_APP',
            isUserLogin: 'auth_app/IS_USER_LOGIN'
        })
    },


    methods: {
        ...mapActions({
            loadPlayerProfile: 'player_profile/LOAD_PLAYER_PROFILE'
        }),

        backward() {
          this.$router.push({ name: 'home' });
        }
    },


    created() {
        this.loadPlayerProfile();
    }
});
</script>


<style scoped lang="scss">
section {
  width: 100%;
}
</style>


