import { App as VueApp, createApp } from "vue";
import Vuelidate from '@vuelidate/core';

import VueUniversalModal from 'vue-universal-modal';
import 'vue-universal-modal/dist/index.css';

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import App from '@/App.vue';
import store from '@/store';
import router from "@/router";



function createVueApp() : VueApp<Element> {
    return createApp( App );
}



const app = createVueApp();
app.use( Vuelidate )
    .use( ToastPlugin )
    .use( VueUniversalModal, { teleportTarget: '#modals', modalComponent: 'Modal' } )
    .use( store )
    .use( router )
    .mount( "#app" );
