<template>
  <div id="app">
      <ClubNavbar></ClubNavbar>
      <router-view></router-view>
  </div>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import ClubNavbar from "@/components/Partials/AppNavbar.vue";
import ClubFooter from "@/components/Partials/AppFooter.vue";


export default defineComponent({
    name: 'App',

    components: { ClubNavbar, ClubFooter }
});
</script>


<style lang="scss">
@import "@/assets/css/app.scss";
@import "@/assets/css/cm-chessboard.scss";
@import "@/assets/css/old-chess.scss";

.section-logo {
    background-color: #d7d7d7;
    height: 70px;
}
.title {
    color: $tavria-blue;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
}
@media only screen and ( max-width: 540px ) {
  .section-logo-name {
    display: none;
  }
}
@media only screen and ( min-width: 768px ) {
  .section-logo-name {
    display: block;
  }
}
</style>
