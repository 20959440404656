<template>
    <section>
            <div class="sessions-container">
                <div class="view-container">
                    <div class="profile-container">
                        <div class="flex-row">
                        <h2>
                            Игра с ИИ
                        </h2>
                        <button @click="backward()" class="green-btn-small">
                            Назад
                        </button>
                        </div>
                        <p>Выберите один из 10 уровней сложности искусственного интеллекта.</p>
                    </div>  
                    <div class="blocks-container">
                        <div v-for="robot, index in robotOpponentsTranslatedFiltered" :key="`robot_${robot.engine}`"
                            class="child-block child-block-blue"
                            :class="getOpacityClass( index )" 
                            @click="createGame( robot.id, robot.level )"
                        >
                            <h3>
                                {{ robot.displayName }}
                            </h3>
                            <div v-if="isGameExist( robot.level )" class="non-played" @click="goExistGame( robot.level )">
                                Не доиграно
                            </div>
                            <span>
                                {{ robot.level }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { GameMainPartResponse } from '@/dto/responses';


export default defineComponent({
    name: 'PlayerOpponentsRobotsTable',

    data() {
        return {
            playerId: parseInt(this.$route.params.playerId as string, 10)
        };
    },


    computed: {
        ...mapGetters({
            robotOpponentsTranslatedFiltered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
            playerGames: 'games/FIXED_PLAYER_GAMES'
        })
    },


    methods: {
        ...mapActions({
            loadRobotOpponents: 'players/LOAD_ROBOT_OPPONENTS',
            loadPlayerGames: 'games/LOAD_PLAYER_GAMES',
            createRobotGame: 'games/CREATE_ROBOT_GAME'
        }),

        getOpacityClass( index: number ) {
            return `opacity-${index}`;
        },

        isGameExist( name: string ) {
            return this.playerGames.findIndex( ( g: GameMainPartResponse ) => g.blackPlayer.displayName === name ) !== -1;
        },

        backward() {
            this.$router.push({ name: 'home' });
        },

        goExistGame( name: string ) {
            const game = this.playerGames.find( ( g: GameMainPartResponse ) => g.blackPlayer.displayName === name );
            if ( game ) this.$router.push({ name: 'game', params: { gameId: game.id } });  /// В существующую игру
        },

        async createGame( opponentPlayerId: number, robotLevel: string ) {
            if (!this.isGameExist( robotLevel )) {
                const new_game = await this.createRobotGame( opponentPlayerId );
                this.$router.push({ name: 'game', params: { gameId: new_game.id } });  /// В созданную игру
            }
        }
    },

    mounted() {
        this.loadPlayerGames();
        this.loadRobotOpponents();
    }
});
</script>


<style scoped lang="scss">
.non-played {
  background-color: $orange;
  color: $tavria-blue;
  padding: 10px;
  position: absolute;
  font-weight: bold;
  right: 0;
  top: 55px;
}
@media (max-width: 768px) {
  .non-played {
    top: 35px;
  }
}
</style>
