import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sessions-container" }
const _hoisted_2 = { class: "view-container" }
const _hoisted_3 = { class: "profile-container" }
const _hoisted_4 = { class: "flex-row" }
const _hoisted_5 = { class: "blocks-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GamesTable = _resolveComponent("GamesTable")!
  const _component_GamesApprovesTable = _resolveComponent("GamesApprovesTable")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Мои игры ", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backward())),
              class: "green-btn-small"
            }, " Назад ")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_GamesTable),
          _createVNode(_component_GamesApprovesTable)
        ])
      ])
    ])
  ]))
}