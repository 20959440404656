
import { Module } from "vuex";
import urlJoin from 'url-join';

import { axios_chess_api_client } from "@/common/axios";
import { PlayerProfileResponse } from "@/dto/responses";


export interface PlayerProfileState {
    _player_profile_data: PlayerProfileResponse,
    _player_history_data: Array<Array<any>>
}


const player_profile: Module<PlayerProfileState, any> = {
    namespaced: true,

    state: {
        _player_profile_data: {} as PlayerProfileResponse,
        _player_history_data: []
    },


    getters: {
        PLAYER_PROFILE: ( state: PlayerProfileState ) : PlayerProfileResponse => {
            return state._player_profile_data
        },
        PLAYER_HISTORY: ( state: PlayerProfileState ) : Array<Array<any>> => {
            return state._player_history_data;
        }
    },


    actions: {
        LOAD_PLAYER_PROFILE: async ({ commit, rootGetters }) : Promise<PlayerProfileResponse> => {
            const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const playerId = await rootGetters['auth_app/AUTH_APP'].player.id;
            const response = await axios_chess_api_client.get(
                urlJoin('player', 'profile', playerId.toString()),
                { 
                    headers: { 
                        'Authorization': access_token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            const playerProfileData = response.data as PlayerProfileResponse;
            commit('SET_PLAYER_PROFILE', playerProfileData);
            console.debug('PLAYER PROFILE: ', playerProfileData);

            const historyData: Array<Array<any>> = [
                [
                    'Date',
                    'ELO',
                    { type: 'string', role: 'annotation' },
                    { type: 'string', role: 'style' }
                ]
            ];
    
            historyData.push([
                new Date( playerProfileData.player.registrationDate  * 1000 ), 
                1200, 
                null, 
                'point { size: 0; }'
            ]);
    
            let currentScore = 1200;
            playerProfileData['eloHistory'].forEach( ( item: any ) => {
                const opponent = ( item.chessGame.whitePlayer.id == playerId ) ? item.chessGame.blackPlayer.displayName : item.chessGame.whitePlayer.displayName;
                const row = [new Date(item.scoreDate * 1000), item.eloRating, opponent, null];
                currentScore = item.eloRating;
                historyData.push( row );
            });

            historyData.push([new Date(), currentScore, null, 'point { size: 0; }']);
            commit('SET_PLAYER_HISTORY', historyData);
            console.debug('PLAYER GAMES HISTORY: ', historyData);

            return playerProfileData;
        }
    },


    mutations: {
        SET_PLAYER_PROFILE: (state: PlayerProfileState, object: PlayerProfileResponse) => {
            state._player_profile_data = object;
        },
        SET_PLAYER_HISTORY: (state: PlayerProfileState, objects: Array<Array<any>>) => {
            state._player_history_data = objects;
        }
    }
};


export default player_profile;
