<template>
    <section class="block orange-block" @click="openHiddenArea">
        <h2>
            Турниры
        </h2>
        <span class="table-length">
            {{ tournaments_all.length }}
        </span>
        <div class="block-desc">
            Участвовать в турнире среди пассажиров
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { TournamentMainPartResponse } from "@/dto/responses";


export default defineComponent({
    name: 'PlayerTournamentsTileBlock',

    data() {
        return {
            showHidden: false,
            tournaments: [] as Array<TournamentMainPartResponse>,
            activePlayers: {},
            chartOptions: {
                legend: 'none',
                backgroundColor: '#4E5D6C',
                pointSize: 10,
                curveType: 'function',
                series: {
                    0: { color: '#E72' }
                },
                hAxis: {
                    textStyle: { color: '#FFF' },
                    gridlines: { color: '#888' },
                    baselineColor: '#FFF'
                },
                vAxis: {
                    textStyle: { color: '#FFF' },
                    gridlines: { color: '#888' },
                    baselineColor: '#FFF'
                }
            },
            intervalID: null as number|null
        };
    },


    computed: {
        ...mapGetters({
            isUserLogin: 'auth_app/IS_USER_LOGIN',
            authenticationData: 'auth_app/AUTH_APP',
            player_profile: 'player_profile/PLAYER_PROFILE',
            tournaments_all: 'tournaments/TOURNAMENTS_ALL',
            tournaments_filtered: 'tournaments/TOURNAMENTS_PLAYER_REGISTERED',
            robots_translated: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED'
        })
    },


    methods: {
        ...mapActions({
            load_player_profile: 'player_profile/LOAD_PLAYER_PROFILE',
            load_tournaments_all: 'tournaments/LOAD_TOURNAMENTS_ALL',
            load_tournaments_player: 'tournaments/LOAD_TOURNAMENTS_PLAYER',
            player_tournament_exit: 'tournaments/TOURNAMENT_PLAYER_EXIT',
            player_tournament_join: 'tournaments/TOURNAMENT_PLAYER_JOIN'
        }),


        openHiddenArea() {
            this.$router.push({ name: 'tournaments' });
        },


        getOpacityClass( index: number ) {
            return `opacity-${index}`;
        },


        /** Вы уже зарегистрированы на турнире ? */
        _isAlreadyTournamentRegister( tournamentId: number ) : boolean {
            return this.tournaments_filtered.has( tournamentId );
        },


        /** Доступен ли турнир для регистрации/выхода ? */
        _isAvailableTournamentRegistration( tournamentStatus: string ) : boolean {
            switch ( tournamentStatus ) {
                case 'REGISTERED': return true;
                default: return false;
            }
        },


        /** Статус турнира */
        _getTournamentName( tournamentStatus: string ) : string {
            switch ( tournamentStatus ) {
                case 'REGISTERED': return 'Регистрация';
                case 'ENDED': return 'Завершен';
                case 'STARTED': return 'В процессе';
                default: return '';
            }
        },


        _handleTournamentJoin(tournamentId: number, event_target: HTMLInputElement) {
            event_target.disabled = true;
            this._stopPolling();
            this.player_tournament_join( tournamentId ).finally( () => {
                event_target.disabled = false;
                this._startPolling();
            });
        },


        _handleTournamentExit(tournamentId: number, event_target: HTMLInputElement) {
            event_target.disabled = true;
            this._stopPolling();
            this.player_tournament_exit( tournamentId ).finally( () => {
                event_target.disabled = false;
                this._startPolling();
            });
        },


        _handleResponse() {
            const profilePlayerId = this.player_profile.player.id as number;
            const historyData : Array<any> = [[
                'Date',
                'ELO',
                { type: 'string', role: 'annotation' },
                { type: 'string', role: 'style' }
            ]];
            historyData.push([
                new Date( this.player_profile.player.registrationDate * 1000 ), 
                1200, 
                null, 
                'point { size: 0; }'
            ]);

            let currentScore = 1200;
            this.player_profile.eloHistory.forEach( ( item: any ) => {
                const opponent = ( item.chessGame.whitePlayer.id == profilePlayerId ) ? item.chessGame.blackPlayer.displayName : item.chessGame.whitePlayer.displayName;
                const row = [
                    new Date(item.scoreDate * 1000), 
                    item.eloRating, 
                    opponent, 
                    null
                ];
                currentScore = item.eloRating;
                historyData.push( row );
            });

            historyData.push([
                new Date(), 
                currentScore, 
                null, 
                'point { size: 0; }'
            ]);
        },


        _getTranslated( name: string ) {
            return this.robots_translated[name] ? this.robots_translated[name].name : name
        },


        _pollingFunc() {
            Promise.all([
                this.load_tournaments_all(),
                this.load_tournaments_player()
            ]);
            this.intervalID = setTimeout(this._pollingFunc, 3000);
        },


        _startPolling() {
            this._stopPolling();
            this._pollingFunc();
        },


        _stopPolling() {
            if ( this.intervalID ) {
                window.clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        }
    },


    mounted() {
        this.load_player_profile();
        this._startPolling();
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
@import "@/assets/css/components/variables.scss";

:host {
    display: block;
}
thead tr {
    background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
    padding: 6px;
}
tr.pvp:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
#chart {
    width: 100%;
}
.portrait {
    float: right;
    margin: 0 0 32px;
    border: 2px solid rgba(255, 255, 255, 0.3);
}
.chess-tournament td, .chess-tournament th {
    padding-bottom: 5px;
    text-align: left;
    vertical-align: middle;
    font-size: 16px;
    padding-left: 0;
}
.chess-tournament button {
    background-color: #06357a;
    color: #fff;
    padding: 5px 15px;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
}
.chess-tournament table {
    max-width: 450px;
}
.paused {
    background-color: #5885c7 !important;
}
</style>
