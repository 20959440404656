import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.fixedPlayerGamesCount)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "circle-green-btn",
          title: "Есть недоигранные партии",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPlayerGames && _ctx.goToPlayerGames(...args)))
        }, _toDisplayString(_ctx.fixedPlayerGamesCount), 1))
      : _createCommentVNode("", true),
    (_ctx.playerApprovalsNeededYourCount)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "circle-btn",
          title: "Новые игры ожидают одобрения",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToPlayerGames && _ctx.goToPlayerGames(...args)))
        }, _toDisplayString(_ctx.playerApprovalsNeededYourCount), 1))
      : _createCommentVNode("", true)
  ]))
}