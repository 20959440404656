import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "row chess-board-container" }
const _hoisted_4 = { class: "col-lg-6 col-lg-offset-2 col-md-8" }
const _hoisted_5 = {
  ref: "chessContainerElem",
  class: "chess-board-outer"
}
const _hoisted_6 = { class: "col-lg-2 col-md-4 history-new" }
const _hoisted_7 = {
  key: 0,
  class: "m-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ChessGameStatus = _resolveComponent("ChessGameStatus")!
  const _component_ChessGameOpening = _resolveComponent("ChessGameOpening")!
  const _component_ChessGameResign = _resolveComponent("ChessGameResign")!
  const _component_ChessGameNavigation = _resolveComponent("ChessGameNavigation")!
  const _component_ChessHistory = _resolveComponent("ChessHistory")!

  return (_openBlock(), _createElementBlock("section", null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { id: "modals" }, null, -1)),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShowGameEndedModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowGameEndedModal) = $event)),
      close: _ctx.closeWinnerModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.gameEndedText), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeWinnerModal( $event )))
          }, " Закрыть "),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeWinnerModal($event, true)))
          }, " Домой ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "close"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ChessGameStatus, { withAIOpponent: _ctx.withAIOpponent }, null, 8, ["withAIOpponent"]),
          _createElementVNode("div", _hoisted_5, null, 512)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.withAIOpponent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.backwardStep())),
                  class: "green-btn"
                }, " Ход назад ")
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ChessGameOpening),
          _createVNode(_component_ChessGameResign),
          _createVNode(_component_ChessGameNavigation),
          _createVNode(_component_ChessHistory)
        ])
      ])
    ])
  ]))
}