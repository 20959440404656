<template>
    <section>
            <div class="history-wrapper mb-75">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="num">&nbsp;</th>
                            <th class="num">Белые</th>
                            <th class="num">Черные</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pair in pairs" :key="`game_pair_${pair.index}`">
                            <td class="num">
                                {{ pair.index }}
                            </td>
                            <td :class="pair.whiteClass" class="num">
                                <a href="#" @click="moveClickW(pair, $event)">
                                    {{ pair.white }}
                                </a>
                            </td>
                            <td :class="pair.blackClass" class="num">
                                <a href="#" @click="moveClickB(pair, $event)">
                                    {{ pair.black }}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";


interface GamePair {
    offset: number,
    index: string,
    white: string|null,
    black: string|null,
    whiteClass: string,
    blackClass: string
}


export default defineComponent({
    name: 'PlayerGameHistory',

    watch: {
        currGameMoveIndex: {
            handler( v: number ) {
                this._moveIndexUpdated( v );
            }
        },
        currPlayerGameMovesPgn: {
            handler( moves: Array<string> ) {
                console.debug('Handle Game Moves Change: ', moves);
                this._movesUpdated( moves );
            },
            deep: true
        }
    },


    data() {
        return {
            pairs: [] as Array<GamePair>
        };
    },


    computed:{
        ...mapGetters({
            currPlayerGameMovesPgn: 'games/CURR_PLAYER_GAMES_MOVES_PGN',
            currGameMoveIndex: 'games/CURR_GAME_MOVE_INDEX'
        })
    },


    methods: {
        ...mapMutations({
            updateCurrGameMoveIndex: 'games/UPDATE_CURR_GAME_MOVE_INDEX'
        }),


        _movesUpdated( moves: Array<string> ) {
            this._refreshPairs( moves );
        },


        _refreshPairs( moves: Array<string> ) {
            if ( this.currPlayerGameMovesPgn.length && this.currGameMoveIndex >= 0 ) {
                const pairs = [];
                let pair = {} as GamePair;
                for (let i = 0; i < moves.length; i++) {
                    if ( i % 2 === 0 ) {
                        pair = {
                            offset: i,
                            index: `${ 1 + i / 2 }.`,
                            white: moves[i],
                            black: null,
                            whiteClass: 'notcurrent',
                            blackClass: 'notcurrent'
                        };
                    } else {
                        pair.black = moves[i];
                        pairs.push( pair );
                    }
                }

                if ( moves.length % 2 == 1 ) {
                    pairs.push( pair );
                }

                const pairIndex = Math.floor( ( this.currGameMoveIndex - 1 ) / 2 );
                console.debug('Pair index is', pairIndex);

                if ( this.currGameMoveIndex > 0 && pairIndex < pairs.length ) {
                    if ( this.currGameMoveIndex % 2 === 0 ) {
                        pairs[pairIndex].blackClass = 'current';
                    } else {
                        pairs[pairIndex].whiteClass = 'current';
                    }
                }
                this.pairs = pairs;
            }
        },


        moveClickW(item: GamePair, event: MouseEvent) {
            event.preventDefault();
            this.updateCurrGameMoveIndex( item.offset + 1 );
        },


        moveClickB(item: GamePair, event: MouseEvent) {
            event.preventDefault();
            this.updateCurrGameMoveIndex( item.offset + 2 );
        },


        _moveIndexUpdated( _currMoveIndex: number ) {
            this._refreshPairs( this.currPlayerGameMovesPgn );
            window.setTimeout( () => {
                const rownumber = Math.ceil( 1 + this.currGameMoveIndex / 2 ) - 1;
                const row = document.querySelector( `tbody > tr:nth-child(${ rownumber })` );
                console.debug( row );
            }, 500);
        }
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
table a:hover {
    text-decoration: underline;
}
table a {
    text-decoration: none;
}
table thead tr {
    background-color: rgba(255, 255, 255, 0.3);
}
.history-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
