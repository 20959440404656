import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "col-md-4" }
const _hoisted_3 = {
  ref: "sideElem",
  id: "side",
  name: "side",
  class: "form-control"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = {
  ref: "validateElem",
  id: "validate",
  name: "validate",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("form", {
      class: "form-horizontal",
      onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
    }, [
      _createElementVNode("fieldset", null, [
        _cache[4] || (_cache[4] = _createElementVNode("legend", null, " Начать игру ", -1)),
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            class: "col-md-4 control-label",
            for: "side"
          }, " Ваши фигуры ", -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("select", _hoisted_3, _cache[1] || (_cache[1] = [
              _createElementVNode("option", { value: "w" }, " Белые ", -1),
              _createElementVNode("option", { value: "b" }, " Черные ", -1)
            ]), 512)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("label", {
            class: "col-md-4 control-label",
            for: "validate"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", _hoisted_6, " Старт ", 512)
          ])
        ])
      ])
    ], 32)
  ]))
}