<template>
    <section>
            <div id="modals"></div>

            <Modal v-model="isShowResignModal" :close="closeModalAndResign">
                <div class="modal">
                    <p>Точно хотите покинуть игру?</p>
                    <button @click="closeModalAndResign">
                        Да
                    </button>
                    <button @click="closeResignModal">
                        Нет
                    </button>
                </div>
            </Modal>

            <Modal v-model="isShowSurrenderModal" :close="closeModalAndSurrender">
                <div class="modal">
                    <p>Точно хотите сдаться?</p>
                    <button @click="closeModalAndSurrender">
                        Да
                    </button>
                    <button @click="closeSurrenderModal">
                        Нет
                    </button>
                </div>
            </Modal>

            <div class="wrapper">
                <div v-show="canDelete">
                    <div class="delete">
                        <span>
                            Пока первый ход не сделан, вы можете 
                        </span>
                        <a @click="showResignModal">
                            удалить эту игру
                        </a>
                        <span>
                            Рейтинг не изменится
                        </span>
                    </div>
                </div>
                <div v-show="canResign">
                    <div class="delete">
                        <button @click="showSurrenderModal" class="green-btn">
                            Сдаться
                        </button>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { GameMainPartResponse, PlayerResponse } from "@/dto/responses";


export default defineComponent({
    name: 'PlayerGameResign',

    data() {
        return {
            canDelete: false,
            canResign: false,
            isShowResignModal: false,
            isShowSurrenderModal: false
        };
    },


    watch: {
        currPlayerGame: {
            handler( curr_game: GameMainPartResponse ) {
                this._gameUpdated( curr_game );
            },
            deep: true
        }
    },


    computed: {
        ...mapGetters({
            authenticationData: 'auth_app/AUTH_APP',
            currPlayerGame: 'games/CURR_PLAYER_GAME'
        }),

        whitePlayer() : PlayerResponse {
            return this.currPlayerGame.whitePlayer || {};
        },

        blackPlayer() : PlayerResponse {
            return this.currPlayerGame.blackPlayer || {};
        }
    },


    methods: {
        ...mapActions({
            leavePlayerGame: 'games/LEAVE_PLAYER_GAME'
        }),


        showResignModal() {
            this.isShowResignModal = true;
        },


        closeResignModal() {
            this.isShowResignModal = false;
        },


        showSurrenderModal() {
            this.isShowSurrenderModal = true;
        },


        closeSurrenderModal() {
            this.isShowSurrenderModal = false;
        },


        _gameUpdated( game: GameMainPartResponse ) {
            if ( !game || !this.authenticationData ) return;
            if ( 
                ( this.currPlayerGame.status === 'OPEN' && this.authenticationData.player ) && 
                ( this.authenticationData.player.id === this.whitePlayer.id || this.authenticationData.player.id === this.blackPlayer.id )
            ) {
                const gameMoves = this.currPlayerGame.moves || [];
                if ( gameMoves.length < 2 ) {
                    this.canDelete = true;
                    this.canResign = false;
                } else {
                    this.canDelete = false;
                    this.canResign = true;
                }
            } else {
                this.canDelete = false;
                this.canResign = false;
            }
        },


        closeModalAndResign( event: MouseEvent ) : boolean {
            this.isShowResignModal = false;
            this.clickResign( event );
            this.$router.push({ name: 'home' });
            return true;
        },


        closeModalAndSurrender( event: MouseEvent ) : boolean {
            this.isShowSurrenderModal = false;
            this.clickResign( event );
            this.$router.push({ name: 'home' });
            return true;
        },


        clickResign( event: MouseEvent ) : boolean {
            event.preventDefault();
            console.debug('Resigning/Deleting current game: ', this.currPlayerGame.id);
            this.leavePlayerGame( this.currPlayerGame.id );
            this.canDelete = false;
            this.canResign = false;
            return false;
        }
    }
});
</script>
