
import { Module } from "vuex";
import urlJoin from 'url-join';

import { DataUtils } from "@/common/functions";
import { axios_chess_api_client } from "@/common/axios";
import { PlayerMainPartResponse } from "@/dto/responses";



export interface PlayerState {
    _tournament_players: Array<PlayerMainPartResponse>
}


const tournament_players: Module<PlayerState, any> = {
    namespaced: true,

    state: {
        _tournament_players: []
    },


    getters: {
        /** GET tournament players */
        TOURNAMENT_PLAYERS: ( state: PlayerState ) : Array<PlayerMainPartResponse> => {
            return state._tournament_players;
        },

        /** GET tournament players count */
        TOURNAMENT_PLAYERS_COUNT: ( state: PlayerState ) : number => {
            return state._tournament_players.length;
        },

        /** GET all closed players */
        TOURNAMENT_PLAYERS_ACTIVE: ( state: PlayerState ) : Array<PlayerMainPartResponse> => {
            return state._tournament_players.filter( _o => {
                return true;
            });
        }
    },


    actions: {
        LOAD_PLAYERS: ({ commit, rootGetters }) => {
            return rootGetters['auth/AUTH_STATE'].wrapRefresh().then( async ( access_token: string ) : Promise<boolean> => {
                const active_tournament = rootGetters['tournaments/ACTIVE_TOURNAMENT'];
                const active_tournamen_id = active_tournament.id;

                if ( DataUtils.isNotEmpty( active_tournamen_id ) ) {
                    const response = await axios_chess_api_client.get(
                        urlJoin('tournament', `${active_tournamen_id}`, 'players'),
                        { 
                            headers: { 
                                'Authorization': access_token 
                            } 
                        }
                    );
                    commit('SET_TOURNAMENT_PLAYERS', response.data);
                }
                return true;
            });
        }
    },


    mutations: {
        SET_TOURNAMENT_PLAYERS: (state: PlayerState, objects: Array<PlayerMainPartResponse>) => {
            state._tournament_players = objects.map( o => o );
        }
    }
};


export default tournament_players;
