
import { Module } from "vuex";
import urlJoin from 'url-join';

import { RobotTranslateConfig } from "@/common/config";
import { parseBoolFromString } from "@/common/config";
import { axios_chess_api_client } from "@/common/axios";
import { PlayerActivity, PlayerResponse, RobotPlayerResponse } from "@/dto/responses";

import ROBOTS_TRANSLATE from '@i18n/crimea_robots.i18n.json';



export interface GamesState {
    _players: Array<PlayerResponse>,
    _robots: Array<RobotPlayerResponse>,
    _playersActivities: Record<string, PlayerActivity>,
    _isDropUntranslatedAi: boolean,
    _robots_translate: Record<string, RobotTranslateConfig>
}


const players: Module<GamesState, any> = {
    namespaced: true,

    state: {
        _players: [],
        _robots: [],
        _playersActivities: {} as Record<string, PlayerActivity>,
        _isDropUntranslatedAi: parseBoolFromString( process.env.VUE_APP_IS_DROP_UNTRANSLATED_AI ),
        _robots_translate: ROBOTS_TRANSLATE
    },


    getters: {
        PLAYER_OPPONENTS: ( state: GamesState ) : Array<PlayerResponse> => {
            return state._players;
        },

        ROBOT_OPPONENTS: ( state: GamesState ) : Array<RobotPlayerResponse> => {
            return state._robots;
        },

        ROBOT_OPPONENTS_TRANSLATED: ( state: GamesState ) : Record<string, RobotTranslateConfig> => {
            return state._robots_translate;
        },

        ROBOT_OPPONENTS_TRANSLATED_FILTERED: ( state: GamesState ) : Array<RobotPlayerResponse> => {
            const results: Array<any> = [];
            for (let i = 0; i < state._robots.length; i++) {
                const o = state._robots[i];

                const translatedKey = o.displayName;
                const foundTranslate = state._robots_translate[translatedKey] || {};

                if ( foundTranslate.name ) {
                    const ob = Object.assign({}, o);
                    ob.level = ob.displayName; 
                    ob.displayName = foundTranslate.name;
                    results.push( ob ); 
                } else if ( !state._isDropUntranslatedAi ) {
                    results.push( o );
                }
            }
            return results;
        },

        PLAYER_OPPONENTS_ACTIVITIES: (state: GamesState, _getters: any, _rootState: any, rootGetters: any) : Record<number, PlayerActivity> => {
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const playerId = authenticationData.player.id; 
            const result = Object.keys( state._playersActivities ).reduce( (res: Record<number, PlayerActivity>, key: string) => {
                const playerActivityObj = state._playersActivities[key];
                if ( playerActivityObj.id !== playerId ) res[playerActivityObj.id] = playerActivityObj;
                return res;
            }, {});
            return result;
        }
    },


    actions: {
        LOAD_PLAYER_OPPONENTS: async ({ commit, rootGetters }) : Promise<Array<PlayerResponse>> => {
            const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            const url_params = new URLSearchParams({ 
                playerId: authenticationData.player.id.toString()
            });
            const response = await axios_chess_api_client.get(
                urlJoin('player', 'search-opponents', '?' + url_params.toString()),
                { 
                    headers: { 
                        'Authorization': access_token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_PLAYER_OPPONENTS', response.data);
            return response.data;
        },

        LOAD_ROBOT_OPPONENTS: async ({ commit, rootGetters }) : Promise<Array<RobotPlayerResponse>> => {
            const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const response = await axios_chess_api_client.get(
                urlJoin('player', 'search-ai'),
                { 
                    headers: { 
                        'Authorization': access_token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_ROBOT_OPPONENTS', response.data);
            return response.data;
        }
    },


    mutations: {
        SET_PLAYER_OPPONENTS: (state: GamesState, objects: Array<PlayerResponse>) => {
            state._players = objects;
        },

        SET_ROBOT_OPPONENTS: (state: GamesState, objects: Array<RobotPlayerResponse>) => {
            state._robots = objects;
        },

        SET_PLAYERS_ACTIVITIES: (state: GamesState, object: Record<string, PlayerActivity>) => {
            state._playersActivities = object;
        }
    }
};


export default players;
