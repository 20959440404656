import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/bg.png'


const _hoisted_1 = { class: "modal-500" }
const _hoisted_2 = { class: "login-section" }
const _hoisted_3 = { class: "register-container" }
const _hoisted_4 = {
  key: 0,
  id: "system-login-form-container",
  style: {"flex":"1 1 100%","display":"flex","justify-content":"center","margin":"0 10px","outline":"#e2e5ff inset 1px"}
}
const _hoisted_5 = {
  class: "simple-fields-container form",
  style: {"text-align":"center","display":"flex","justify-content":"space-around"}
}
const _hoisted_6 = {
  for: "car",
  class: "login-form-label form-field__one",
  style: {"display":"flex","width":"auto","margin":"0","padding":"0 10px"}
}
const _hoisted_7 = {
  for: "place",
  class: "login-form-label form-field__one",
  style: {"display":"flex","width":"auto","margin":"0","padding":"0 10px"}
}
const _hoisted_8 = { class: "send-button-container" }
const _hoisted_9 = { class: "simple-fields-container form" }
const _hoisted_10 = {
  for: "register",
  class: "login-form-label form-field__one"
}
const _hoisted_11 = { class: "send-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.isRulesModalShowed,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isRulesModalShowed) = $event)),
      close: _ctx.showRulesModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Правила", -1)),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "bg-blue" }, "Турниры проводятся по олимпийской системе — навылет.", -1)),
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-blue" }, "Каждому игроку на все ходы отводится 30 минут.", -1)),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "bg-blue" }, "Игрок, не уложившийся в лимит времени, автоматически признаётся проигравшим.", -1)),
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-blue" }, "В случае ничьей переигровка не проводится, победителем признаётся игрок, затративший меньше времени на ходы.", -1)),
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "bg-blue" }, "Победители игр в каждом раунде проходят в следующий круг.", -1)),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-blue" }, "Победители полуфиналов выходят в финал, который проводится за настоящей шахматной доской в вагоне-ресторане.", -1)),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRulesModal( $event )))
          }, " Закрыть ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "close"]),
    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "bg" }, [
      _createElementVNode("img", { src: _imports_0 })
    ], -1)),
    _createElementVNode("section", _hoisted_2, [
      _cache[21] || (_cache[21] = _createElementVNode("h1", null, "Шахматы Таврии", -1)),
      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "description" }, " Во время поездки на поезде «Таврия» у пассажиров есть возможность поиграть в шахматы с искусственным интеллектом, другими пассажирами текущего рейса, а также принять участие в настоящем шахматном турнире. Следите за объявлениями по громкой связи. ", -1)),
      _createElementVNode("a", {
        href: "javascript:;",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRulesModal( $event )))
      }, "Правила"),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showLogin && _ctx.showLogin(...args))),
            class: _normalizeClass(_ctx.loginControlButtonClass)
          }, " Вход в игру ", 2)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showRegister && _ctx.showRegister(...args))),
            class: _normalizeClass(_ctx.registerControlButtonClass)
          }, " Регистрация ", 2)
        ])
      ]),
      _createElementVNode("div", null, [
        (( _ctx.isDebugMode || !_ctx.isDemoMode ))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("form", {
                id: "system-login-form",
                name: "system-login-form",
                onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _cache[20] || (_cache[20] = _createElementVNode("p", {
                  class: "title",
                  style: {"text-align":"center"}
                }, " Панель отладки ", -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, [
                    _withDirectives(_createElementVNode("input", {
                      id: "car",
                      placeholder: "Вагон",
                      required: "",
                      maxlength: "2",
                      type: "tel",
                      class: _normalizeClass(_ctx.errorClass),
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.system_login_form_data.car) = $event))
                    }, null, 2), [
                      [_vModelText, _ctx.system_login_form_data.car]
                    ])
                  ]),
                  _createElementVNode("label", _hoisted_7, [
                    _withDirectives(_createElementVNode("input", {
                      id: "place",
                      placeholder: "Место",
                      required: "",
                      maxlength: "3",
                      type: "tel",
                      class: _normalizeClass(_ctx.errorClass),
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.system_login_form_data.place) = $event))
                    }, null, 2), [
                      [_vModelText, _ctx.system_login_form_data.place]
                    ])
                  ])
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("form", {
          id: "login-form",
          name: "login-form",
          class: "form-container",
          onSubmit: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.loginUser && _ctx.loginUser(...args))),
              class: "button reg-btn"
            }, " Войти ")
          ])
        ], 544), [
          [_vShow, _ctx.isLoginSelected]
        ]),
        _withDirectives(_createElementVNode("form", {
          id: "register-form",
          name: "register-form",
          class: "form-container",
          onSubmit: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                id: "register",
                placeholder: "Придумайте логин",
                class: _normalizeClass(_ctx.errorClass),
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.register_form_data.displayName) = $event))
              }, null, 2), [
                [_vModelText, _ctx.register_form_data.displayName]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.registerUser && _ctx.registerUser(...args))),
              class: "button reg-btn"
            }, " Регистрация ")
          ])
        ], 544), [
          [_vShow, _ctx.isRegisteredSelected]
        ])
      ])
    ])
  ], 64))
}