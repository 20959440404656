<template>
    <section>  
            <div v-if="!withAIOpponent && isGameActuallyStarted()">
                <span v-show="playerAvailableSec" class="player-time">
                    {{ statusTimeStrPlayer }}
                </span>
                <span v-show="opponentAvailableSec" class="player-time">
                    Противник: {{ statusTimeStrOpponent }}
                </span>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment, { Moment } from 'moment';

import { CHESS_GAME_STATUS } from '@/common/classes';
import { GameMainPartResponse, ResponseDateConverter } from '@/dto/responses';

const MAX_PLAYER_MOVES_TIME_SEC = parseInt( process.env.VUE_APP_MAX_PLAYER_MOVES_TIME_MIN ) * 60;
const CHECK_MOVE_INTERVAL_MSEC = parseInt( process.env.VUE_APP_CHECK_MOVE_INTERVAL_SEC ) * 1000;


interface TimesSummPair {
    whiteTimesSummSec: number, 
    blackTimesSummSec: number
}


export default defineComponent({
    name: 'GameStatusTimer',

    props: {
        withAIOpponent: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    watch: {
        currPlayerGame: {
            handler( game: GameMainPartResponse ) {
                this._refreshTimer( game );
            }
        }
    },


    data() {
        return {
            statusTimeStrPlayer: '',
            statusTimeStrOpponent: '',
            playerTimesSummSec: 0,
            opponentTimesSummSec: 0,
            playerAvailableSec: 0,
            opponentAvailableSec: 0,
            playerIntervalID: null as number|null,
            opponentIntervalID: null as number|null,
            _timerCache: {} as Record<string, any>
        };
    },


    computed: {
        ...mapGetters({
            authenticationData: 'auth_app/AUTH_APP',
            currPlayerGame: 'games/CURR_PLAYER_GAME',
            robotOpponentsTranslatedFiltered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
        }),

        startDatePending() : Moment {
            return ResponseDateConverter( this.currPlayerGame.startDatePending );
        }
    },


    methods: {
        ...mapActions({
            leavePlayerGame: 'games/LEAVE_PLAYER_GAME'
        }),


        isGameActuallyStarted() : boolean {
            if ( this.currPlayerGame.moves ) {  /// Костыль для ещё не загруженной игры
                if ( this.currPlayerGame.moves.length > 0 ) return true;
                else return moment().utc().isSameOrAfter( this.startDatePending );
            } 
            else return false;
        },


        _getTranslated( name: string ) : string {
            return this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name) ? this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name).displayName : name
        },


        /**
         * Пересчёт общего затраченного времени для каждой стороны (с кэшированием состояния)
         */
        _calcPlayersTimesSumm(game: GameMainPartResponse) : TimesSummPair {
            let beforeStartAdd = ResponseDateConverter( game.moves[0].date ).diff(ResponseDateConverter( game.startDatePending ), 'seconds');
            if ( beforeStartAdd < 0 ) beforeStartAdd = 0;  /// Если первый ход был сделан раньше автоматического старта игры - обнуляем дельту
            /// USE CACHE
            let ind = ( this._timerCache['ind'] === undefined ) ? 1 : this._timerCache['ind'];
            let prevMoveTime = this._timerCache['prevMoveTime'] || ResponseDateConverter( game.startDatePending );  /// Начинаем считать с фактического старта игры
            let whiteTimesSummSec = this._timerCache['whiteTimesSummSec'] || ( 0 + beforeStartAdd );
            let blackTimesSummSec = this._timerCache['blackTimesSummSec'] || 0;

            for ( ind; ind < game.moves.length; ind++ ) {
                const move_date = game.moves[ind].date;
                const moveTime = ResponseDateConverter( move_date );
                const diffTimeSec = moveTime.diff(prevMoveTime, 'seconds');
                if ( ind % 2 === 0 )
                    whiteTimesSummSec += diffTimeSec;
                else
                    blackTimesSummSec += diffTimeSec;
                prevMoveTime = moveTime;
            }
            /// WRITE CACHE
            Object.assign(this._timerCache, {
                ind: ind,
                prevMoveTime: prevMoveTime,
                whiteTimesSummSec: whiteTimesSummSec,
                blackTimesSummSec: blackTimesSummSec
            });
            return { whiteTimesSummSec, blackTimesSummSec };
        },


        /** Дата предыдущего хода игрока/оппонента */
        _getLastMoveDate( game: GameMainPartResponse ) : Moment {
            let result = ResponseDateConverter( game.startDatePending );
            if ( game.moves.length > 0 ) {
                const yourLastMove = game.moves.at( -1 );
                if ( yourLastMove ) result = ResponseDateConverter( yourLastMove.date );

            } 
            return result;
        },


        _refreshTimer( game: GameMainPartResponse ) {
            switch ( game.status ) {
                case CHESS_GAME_STATUS.OPEN : {
                    const prevPlayerDt = this._getLastMoveDate( game );
                    const prevOpponentDt = this._getLastMoveDate( game );
                    if ( !this.withAIOpponent && game.moves.length > 0 ) {
                        const { whiteTimesSummSec, blackTimesSummSec } = this._calcPlayersTimesSumm( game );
                        if ( this.authenticationData.player.id === game.whitePlayer.id ) {
                            this.playerTimesSummSec = whiteTimesSummSec;
                            this.opponentTimesSummSec = blackTimesSummSec;
                        } else {
                            this.opponentTimesSummSec = whiteTimesSummSec;
                            this.playerTimesSummSec = blackTimesSummSec;
                        }
                    }
                    if ( game.nextPlayer.id === this.authenticationData.player.id )  /// Противник уже сходил, следующий ваш ход
                    {
                        if ( !this.withAIOpponent ) this._startPolling(prevPlayerDt, false);
                        this.$emit('game-status-updated', 'Ваш ход', 'wrapper');
                    } 
                    else  /// Вы уже сходили, следующий ход оппонента
                    {
                        if ( !this.withAIOpponent ) this._startPolling(prevOpponentDt, true);
                        this.$emit('game-status-updated', `Ход противника ${ this._getTranslated(game.nextPlayer.displayName) }`, 'wrapper');
                    } 
                    break;
                }
                case CHESS_GAME_STATUS.WHITEWON: {
                    this._stopPolling();
                    this.$emit('game-status-updated', `Игра окончена: ${this._getTranslated(game.whitePlayer.displayName)} победил!`);
                    break;
                }
                case CHESS_GAME_STATUS.BLACKWON: {
                    this._stopPolling();
                    this.$emit('game-status-updated', `Игра окончена: ${this._getTranslated(game.blackPlayer.displayName)} победил!`);
                    break;
                }
                case CHESS_GAME_STATUS.PAT: {
                    this._stopPolling();
                    this.$emit('game-status-updated', 'Игра окончена: Ничья!');
                    break;
                }
            }
        },


        _countBeforeStart() {
            const curr_diff_sec = Math.ceil( this.startDatePending.diff(moment().utc(), 'seconds', true) );  /// Дельта в секундах между текущим временем и последним ходом игрока
            const timeBefore = moment.utc( curr_diff_sec * 1000 ).format( 'HH:mm:ss' );
            // this._handleGameStatusStyle( `До начала партии: ${ timeBefore }` );
            this.$emit('game-status-updated', `До начала партии: ${ timeBefore }`);
        },


        _leaveGameByExpiredTime() {
            this._stopPolling();
            this.statusTimeStrPlayer = '';
            this.statusTimeStrOpponent = '';
            // const gameIdStr = ( this.currPlayerGame as GameMainPartResponse ).id;
            // this.leavePlayerGame( gameIdStr );
        },


        _countAfterStart(playerMoveWaitingStart: Moment, isForOpponentPlayer: boolean) {
            const currMoment = moment.utc();
            // if ( this.curr_player_game.moves.length > 0 || currMoment.isSameOrAfter( this.startDatePending ) ) {
            if ( this.currPlayerGame.moves.length > 0 ) {
                const curr_diff_sec = currMoment.diff(playerMoveWaitingStart, 'seconds');  /// Дельта в секундах между текущим временем и последним ходом игрока
                this.playerAvailableSec = Math.max(MAX_PLAYER_MOVES_TIME_SEC - this.playerTimesSummSec, 0);
                this.opponentAvailableSec = Math.max(MAX_PLAYER_MOVES_TIME_SEC - this.opponentTimesSummSec, 0);
                if ( !isForOpponentPlayer ) 
                {
                    this.playerAvailableSec -= curr_diff_sec;  /// Общее оставшееся время для игрока с учётом дельты
                    if ( this.playerAvailableSec > 0 ) {
                        this.statusTimeStrPlayer = moment.utc( this.playerAvailableSec * 1000 ).format( 'HH:mm:ss' );
                        this.statusTimeStrOpponent = moment.utc( this.opponentAvailableSec * 1000 ).format( 'HH:mm:ss' );
                    }
                    else this._leaveGameByExpiredTime();  /// Принудительный выход из игры по истечению времени для ходов
                } 
                else {
                    this.opponentAvailableSec -= curr_diff_sec;  /// Общее оставшееся время для игрока с учётом дельты
                    this.statusTimeStrPlayer = moment.utc( this.playerAvailableSec * 1000 ).format( 'HH:mm:ss' );
                    this.statusTimeStrOpponent = moment.utc( this.opponentAvailableSec * 1000 ).format( 'HH:mm:ss' );
                }
            } else {
                const secondsAfterStart = currMoment.diff(this.startDatePending, 'seconds');
                const formattedTimeAfterStart = moment.utc( secondsAfterStart * 1000 ).format( 'mm:ss' );
                this.$emit('game-status-updated', `Партия началась: ${ formattedTimeAfterStart } назад`);
            }
        },


        countTimerFunc( playerMoveWaitingStart: Moment, isForOpponentPlayer: boolean) {
            if ( !this.isGameActuallyStarted() ) this._countBeforeStart();
            else this._countAfterStart(playerMoveWaitingStart, isForOpponentPlayer);
        },


        _pollingFuncComm(playerMoveWaitingStart: Moment, isForOpponentPlayer: boolean) {
            this.countTimerFunc(playerMoveWaitingStart, isForOpponentPlayer);
            const intervalIDComm = setTimeout(
                this._pollingFuncComm.bind(this, playerMoveWaitingStart, isForOpponentPlayer), 
                CHECK_MOVE_INTERVAL_MSEC
            );
            if ( !isForOpponentPlayer ) this.playerIntervalID = intervalIDComm;
            else this.opponentIntervalID = intervalIDComm;
        },


        _stopPolling() {
            if ( this.playerIntervalID ) clearTimeout( this.playerIntervalID );
            if ( this.opponentIntervalID ) clearTimeout( this.opponentIntervalID );
            this.playerIntervalID = null;
            this.opponentIntervalID = null;
        },


        _startPolling(playerMoveWaitingStart: Moment, isForOpponentPlayer = false) {
            this._stopPolling();
            this._pollingFuncComm(playerMoveWaitingStart, isForOpponentPlayer);
        }
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}
.red-status {
    display: flex;
    color: $rzd-red;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    font-size: 16px;
}
.game-data p {
    color: $tavria-green;
    font-size: 16px;
    font-weight: bold;
}
.red-status p {
    color: $rzd-red;
    font-size: 16px;
    font-weight: bold;
}
.gamer-name {
    font-size: 24px;
}
.gamer-name a {
    font-weight: bold;
    text-decoration: none;
    color: #000;
}
.player-time {
    font-size: 24px;
    color: $rzd-red;
}
@media (max-width: 768px) {
    .wrapper {
        width: 85%;
        margin: 0 auto;
    }
}
</style>
