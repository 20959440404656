import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "delete" }
const _hoisted_5 = { class: "delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("section", null, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { id: "modals" }, null, -1)),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShowResignModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowResignModal) = $event)),
      close: _ctx.closeModalAndResign
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[8] || (_cache[8] = _createElementVNode("p", null, "Точно хотите покинуть игру?", -1)),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModalAndResign && _ctx.closeModalAndResign(...args)))
          }, " Да "),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeResignModal && _ctx.closeResignModal(...args)))
          }, " Нет ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "close"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShowSurrenderModal,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isShowSurrenderModal) = $event)),
      close: _ctx.closeModalAndSurrender
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("p", null, "Точно хотите сдаться?", -1)),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeModalAndSurrender && _ctx.closeModalAndSurrender(...args)))
          }, " Да "),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeSurrenderModal && _ctx.closeSurrenderModal(...args)))
          }, " Нет ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "close"]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, [
          _cache[10] || (_cache[10] = _createElementVNode("span", null, " Пока первый ход не сделан, вы можете ", -1)),
          _createElementVNode("a", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.showResignModal && _ctx.showResignModal(...args)))
          }, " удалить эту игру "),
          _cache[11] || (_cache[11] = _createElementVNode("span", null, " Рейтинг не изменится ", -1))
        ])
      ], 512), [
        [_vShow, _ctx.canDelete]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.showSurrenderModal && _ctx.showSurrenderModal(...args))),
            class: "green-btn"
          }, " Сдаться ")
        ])
      ], 512), [
        [_vShow, _ctx.canResign]
      ])
    ])
  ]))
}