import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sessions-container" }
const _hoisted_2 = { class: "view-container" }
const _hoisted_3 = { class: "profile-container" }
const _hoisted_4 = { class: "flex-row" }
const _hoisted_5 = { class: "blocks-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = {
  key: 3,
  class: "bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Онлайн ", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBackward && _ctx.goBackward(...args))),
              class: "green-btn-small"
            }, " Назад ")
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Игроки в сети", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerOpponents, (player) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `player_${player.id}`,
              class: _normalizeClass(["child-block child-block-green", _ctx.getOpacityClass( player.id )]),
              onClick: ($event: any) => (_ctx.createGameApprove( player.id ))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.getActivityPlayerClass( player.id )),
                title: _ctx.getActivityPlayerTitle( player.id )
              }, null, 10, _hoisted_7),
              _createElementVNode("h3", null, _toDisplayString(player.displayName), 1),
              (_ctx._getOpponentStatus( player.id ) === 2)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "non-played",
                    onClick: ($event: any) => (_ctx.approveGame( player.id ))
                  }, " Одобрить ", 8, _hoisted_8))
                : _createCommentVNode("", true),
              (_ctx._getOpponentStatus( player.id ) === 3)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "non-played",
                    onClick: ($event: any) => (_ctx.goGame( player.id ))
                  }, " Играть ", 8, _hoisted_9))
                : _createCommentVNode("", true),
              (_ctx._getOpponentStatus( player.id ) === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Отправить приглашение "))
                : _createCommentVNode("", true),
              (_ctx._getOpponentStatus( player.id ) === 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Ожидает одобрения противником "))
                : _createCommentVNode("", true)
            ], 10, _hoisted_6))
          }), 128))
        ])
      ])
    ])
  ]))
}