import moment, { Moment } from "moment";

import { CHESS_GAME_STATUS, CHESS_TOURNAMENT_STATUS } from "@/common/classes";



export function ResponseDateConverter( date: GameDate ) : Moment {
    return moment.utc([
        date.year, 
        date.monthValue - 1,  /// Starts with 0 in Moment.js
        date.dayOfMonth, 
        date.hour, 
        date.minute, 
        date.second
    ]);
}


//// TOURNAMENTS
export interface TournamentMainPartResponse {
    id: number,
    name: string,
    status: CHESS_TOURNAMENT_STATUS,
    currStage: number,
    isFinalStage: boolean,
    isCurrStageEnded: boolean
}


//// PLAYERS
export interface PlayerMainPartResponse {
    id: number,
    displayName: string,
    userCar: string,
    userPlace: string
}


//// PLAYERS
export interface PvpStatus {
	wins: number
    draws: number
    losses: number
}
export interface PvpStatusItem {
	opponent: PlayerResponse,
	pvpStatus: PvpStatus
}
export interface PlayerResponse {
    id: number,
    displayName: string
    registrationDate: number
}
export interface RobotPlayerResponse {
    id: number,
    displayName: string,
    registrationDate: number,
    engine: string,
    parameters: string,
    cacheable: boolean,
    level: string
}
export interface PlayerProfileResponse {
    player: PlayerResponse,
	pvpStatistics: Array<PvpStatusItem>,
    eloHistory: Array<any>
}
export interface PlayerActivity {
    id: number,
    name: string,
    status: boolean
}
export interface PlayerActivitiesResponse {
    playerId: number,
    playerName: string,
    data: Record<string, PlayerActivity>
}
///


//// AUTH APP
export interface AuthenticationResult {
    token: string,
    accountEmail: string,
    player: PlayerResponse
}


//// GAMES
export interface ChessMoveResponse {
    id: number,
    playerId: number,
    pgn: string,
    date: GameDate
}

export interface NewGamesResponse {
    data: GameMainPartResponse,
    status: number
}

export interface GameDate {
    year: number, 
    monthValue: number, 
    dayOfMonth: number, 
    hour: number, 
    minute: number, 
    second: number
}

export interface GameMainPartResponse {
    id: number,
    tournamentId: number,
    tournamentStage: number|null,
    isFinalStage: boolean,
    status: CHESS_GAME_STATUS,
    whitePlayer: PlayerMainPartResponse,
    blackPlayer: PlayerMainPartResponse,
    nextPlayer: PlayerResponse,
    isOffline: boolean,
    startDatePendingTimeMin: number,
    startDate: GameDate,
    startDatePending: GameDate,
    endDatePlanned: GameDate,
    secondsBeforeActuallyStart: number,
    moves: Array<ChessMoveResponse>
    ///////////////// APPROVE PART
    approveId: number,
    isApproval: boolean,
    isWhitePlayerApproved: boolean,
    isBlackPlayerApproved: boolean
}

export interface GameApproveResponse {
    id: number,
    whitePlayer: PlayerMainPartResponse,
    blackPlayer: PlayerMainPartResponse,
    isWhitePlayerApprove: boolean,
    isBlackPlayerApprove: boolean,
    gameId?: number
}

export interface GameOpeningsResponse {
    name: string,
    variants: Array<string>
}
