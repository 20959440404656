<template>
    <section class="block green-block" @click="openHiddenArea">
        <h2>
            Онлайн
        </h2>
        <span class="table-length">
            {{ playerOpponents.length }}
        </span>
        <div class="block-desc">
            Играть c соперником на время
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';


export default defineComponent({
    name: 'PlayerOpponentsHumansTileBlock',

    data() {
        return {
            playerId: parseInt(this.$route.params.playerId as string, 10)
        };
    },


    computed: {
        ...mapGetters({
            playerOpponents: 'players/PLAYER_OPPONENTS'
        }),

        isOpponentsExists() : boolean {
            return ( this.playerOpponents.length > 0 );
        }
    },


    methods: {
        ...mapActions({
            loadPlayerOpponents: 'players/LOAD_PLAYER_OPPONENTS',
            createPlayerGameApprove: 'games/CREATE_PLAYER_GAME_APPROVE'
        }),

        openHiddenArea() {
            this.$router.push({ name: 'online' });
        }
    },


    mounted() {
        this.loadPlayerOpponents();
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
</style>
