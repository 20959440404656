import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-length" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: "block orange-block",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openHiddenArea && _ctx.openHiddenArea(...args)))
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Турниры ", -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.tournaments_all.length), 1),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "block-desc" }, " Участвовать в турнире среди пассажиров ", -1))
  ]))
}