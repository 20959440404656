<template>
    <Modal v-model="isRulesModalShowed" :close="showRulesModal">
        <div class="modal-500">
            <h3>Правила</h3>
            <div class="bg-blue">Турниры проводятся по олимпийской системе — навылет.</div>
            <div class="text-blue">Каждому игроку на все ходы отводится 30 минут.</div>
            <div class="bg-blue">Игрок, не уложившийся в лимит времени, автоматически признаётся проигравшим.</div>
            <div class="text-blue">В случае ничьей переигровка не проводится, победителем признаётся игрок, затративший меньше времени на ходы.</div>
            <div class="bg-blue">Победители игр в каждом раунде проходят в следующий круг.</div>
            <div class="text-blue">Победители полуфиналов выходят в финал, который проводится за настоящей шахматной доской в вагоне-ресторане.</div>
            <button @click="showRulesModal( $event )">
                Закрыть
            </button>
        </div>
    </Modal>

    <div class="bg"><img src="@/assets/images/bg.png"/></div>

    <section class="login-section">
            <h1>Шахматы Таврии</h1>

            <div class="description">
                Во время поездки на поезде «Таврия» у пассажиров есть возможность поиграть в шахматы с искусственным интеллектом, другими пассажирами текущего рейса, а также принять участие в настоящем шахматном турнире. Следите за объявлениями по громкой связи.
            </div>

            <a href="javascript:;" @click="showRulesModal( $event )">Правила</a>  
            <div class="register-container">
                <div>
                    <a @click="showLogin" :class="loginControlButtonClass">
                        Вход в игру
                    </a>
                </div>
                <div>
                    <a @click="showRegister" :class="registerControlButtonClass">
                        Регистрация
                    </a>
                </div>
            </div>

            <div>
                <div v-if="( isDebugMode || !isDemoMode )" id="system-login-form-container" style="flex: 1 1 100%; display: flex; justify-content: center; margin: 0 10px; outline: #e2e5ff inset 1px;">
                    <form id="system-login-form" name="system-login-form" v-on:submit.prevent>
                        <p class="title" style="text-align: center;">
                            Панель отладки
                        </p>
                        <div class="simple-fields-container form" style="text-align: center; display: flex; justify-content: space-around;">
                            <label for="car" class="login-form-label form-field__one" style="display: flex; width: auto; margin: 0; padding: 0 10px;">
                                <input id="car" placeholder="Вагон"  required maxlength="2" type="tel" :class="errorClass" v-model="system_login_form_data.car"/>
                            </label>
                            <label for="place" class="login-form-label form-field__one" style="display: flex; width: auto; margin: 0; padding: 0 10px;">
                                <input id="place" placeholder="Место" required maxlength="3" type="tel" :class="errorClass" v-model="system_login_form_data.place"/>
                            </label>
                        </div>
                    </form>
                </div>

                <form v-show="isLoginSelected" id="login-form" name="login-form" class="form-container" v-on:submit.prevent>
                    <div class="send-button-container">
                        <button @click="loginUser" class="button reg-btn">
                            Войти
                        </button>
                    </div>
                </form>

                <form v-show="isRegisteredSelected" id="register-form" name="register-form" class="form-container" v-on:submit.prevent>
                    <div class="simple-fields-container form">
                        <label for="register" class="login-form-label form-field__one">
                            <input id="register" placeholder="Придумайте логин" :class="errorClass" v-model="register_form_data.displayName"/>
                        </label>
                    </div>
                    <div class="send-button-container">
                        <button @click="registerUser" class="button reg-btn">
                            Регистрация
                        </button>
                    </div>
                </form>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent, reactive } from "vue";
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from "@vuelidate/core";

import { uuid4 } from "@/common/functions";
import { minLength, required } from "@vuelidate/validators";
import { AppAuthLoginData, AppAuthRegisterData, CommonLoginData, SystemAuthRequestData } from "@/dto/requests";
import { IS_DEMO_MODE_ENABLED, IS_DEV_MODE_ENABLED } from "@/common/globals";


export default defineComponent({
    name: 'Auth',

    data() {
        return {
            isRulesModalShowed: false,
            isLoginShow: false,
            isRegisteredShow: false,
            errorClass: ''
        };
    },

    setup() {
        const register_form_data = reactive<AppAuthRegisterData>({
            displayName: ''
        });
        const login_form_data = reactive<AppAuthLoginData>({
            displayName: null
        });
        const system_login_form_data = reactive<SystemAuthRequestData>({
            car: '',
            place: ''
        });
        const register_form_validate_rules = {
            displayName: { required, minLength: minLength( 3 ) }
        };
        const login_form_validate_rules = {
            displayName: { minLength: minLength( 3 ) }
        };
        const system_login_form_validate_rules = {
            car: { required, minLength: minLength( 2 ) },
            place: { required, minLength: minLength( 3 ) }
        };
        const v1$ = useVuelidate(login_form_validate_rules, login_form_data);
        const v2$ = useVuelidate(register_form_validate_rules, register_form_data);
        const v3$ = useVuelidate(system_login_form_validate_rules, system_login_form_data);
        return { login_form_data, register_form_data, system_login_form_data, v1$, v2$, v3$ };
    },


    computed: {
        ...mapGetters({
            isUserRegistered: 'auth_app/IS_USER_REGISTERED'
        }),

        isDebugMode() : boolean {
            return IS_DEV_MODE_ENABLED;
        },

        isDemoMode() : boolean {
            return IS_DEMO_MODE_ENABLED;
        },

        /// Выбор меню по клику или автоматически при инициализации
        isRegisteredSelected() : boolean {
            return ( this.isRegisteredShow || !this.isUserRegistered ) && !this.isLoginShow;
        },

        /// Выбор меню по клику или автоматически при инициализации
        isLoginSelected() : boolean {
            return ( this.isLoginShow || this.isUserRegistered ) && !this.isRegisteredShow;
        },

        registerControlButtonClass() : string {
            /// Проверить это условие! (возможна оптимизация ?)
            if ( this.isRegisteredSelected && !this.isLoginShow ) return 'selected';
            else return '';
        },

        loginControlButtonClass() : string {
            /// Проверить это условие! (возможна оптимизация ?)
            if ( !this.isUserRegistered ) return 'hide';
            else if ( this.isLoginSelected && !this.isRegisteredShow ) return 'selected';
            else return '';
        }
    },


    methods: {
        ...mapActions({
            appLogin: 'auth_app/LOGIN',
            appRegister: 'auth_app/REGISTER'
        }),

        showRulesModal( _event: MouseEvent ) : boolean {
            this.isRulesModalShowed = !this.isRulesModalShowed;
            return true;
        },

        showLogin() {
           this.isLoginShow = true;
           this.isRegisteredShow = false;
        },

        showRegister() {
           this.isLoginShow = false 
           this.isRegisteredShow = true;
        },

        redirectAfterAuth() {
            if ( this.isDemoMode ) this.$router.push({ name: 'ai' });  /// Для демо-режима перенаправляем сразу на страницу выбора игры с ИИ
            else this.$router.push({ name: 'home' });
        },

        /**
         * При логине <car> и <place> используются только если включен <DEV_MODE> (т.к. куки с токеном не сохраняются при работе с бэкендом на другом хосте)
         * В демо-режиме <car> и <place> от пользователя они не требуются (генерируются случайно либо через хардкод)
         */
        getSystemAuthData() : SystemAuthRequestData {
            const car = this.isDemoMode ? ( this.system_login_form_data.car || `DEMO_CAR` ) : this.system_login_form_data.car;
            const place = this.isDemoMode ? ( this.system_login_form_data.place || `DEMO_PLACE_${uuid4()}` ) : this.system_login_form_data.place;
            return { car, place } as SystemAuthRequestData;
        },

        async loginUser() {
            if ( !this.v1$.$invalid ) {
                const payload: CommonLoginData = {
                    systemLoginData: this.getSystemAuthData(),
                    appLoginData: {
                        displayName: this.login_form_data.displayName
                    }
                };
                await this.appLogin( payload );
                this.redirectAfterAuth();
            }
            else console.warn( 'Please fill Login Form correctly!' );
        },

        async registerUser() {
            if ( !this.v2$.$invalid ) {
                const payload: CommonLoginData = {
                    systemLoginData: this.getSystemAuthData(),
                    appLoginData: {
                        displayName: this.register_form_data.displayName
                    }
                };
                await this.appRegister( payload );
                this.redirectAfterAuth();
            }
            else console.warn( 'Please fill Register Form correctly!' );
        }
    }
});
</script>


<style scoped lang="scss">
@import "@/assets/css/components/_main.scss";

.title {
  color: $black;
  text-align: left;
  margin: 0;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 12px;
}
.form-group-error {
  outline: 1px solid red;
}
h1 {
    text-align: left;
}
.description {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}
.register-container {
    display: flex;
    width: 100%;
    margin: 20px 0;
    align-items: center;
    color: #000 !important;
}
.register-container > div > a {
    cursor: pointer;
    margin-right: 20px;
    font-size: 20px;
}
.selected {
    display: block;
    font-size: 30px !important;
    font-weight: 600;
    color: #000 !important;
}
.hide {
    display: none;
}
.reg-btn {
    height: 40px;
    width: 180px;
}
.login-section {
    display: flex; 
    width: 800px; 
    margin: 0 auto; flex-wrap: wrap;
}
.form-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.bg-blue {
    background-color: $tavria-blue;
    color: #fff;
    padding: 10px 20px;
}
.text-blue {
    color: $tavria-blue;
    padding: 10px 20px;
}
.bg {
    text-align: center;
}
.bg img {
    width: -webkit-fill-available;
}
@media (max-width: 768px) {
    .login-section {
        width: 100%;
        margin: 0 10px;
    }
}
</style>
