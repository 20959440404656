<template>
    <section>
        <div class="sessions-container">
            <div class="view-container">
                <div class="profile-container">
                <div class="flex-row">
                    <h2>
                        Игровая история
                    </h2>
                    <button @click="backward()" class="green-btn-small">
                        Назад
                    </button>
                </div>
                </div>  
                <div class="blocks-container">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="enemy-name">Противник</th>
                                <th class="num">Победа</th>
                                <th class="num">Ничья</th>
                                <th class="num">Поражение</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="pvp" v-for="statistic in playerProfile.pvpStatistics" :key="`player_history_${statistic.opponent.id}`">
                                <td class="enemy-name">
                                    {{ _getTranslated( statistic.opponent.displayName ) }}
                                </td>
                                <td class="num">{{ statistic.pvpStatus.wins }}</td>
                                <td class="num">{{ statistic.pvpStatus.draws }}</td>
                                <td class="num">{{ statistic.pvpStatus.losses }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";


export default defineComponent({
    name: 'PlayerHistory',

    data() {
        return {
            playerId: parseInt(this.$route.params.playerId as string, 10)
        };
    },


    computed: {
        ...mapGetters({
            playerProfile: 'player_profile/PLAYER_PROFILE',
            robotOpponentsTranslatedFiltered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
        })
    },


    methods: {
      ...mapActions({
          loadPlayerProfile: 'player_profile/LOAD_PLAYER_PROFILE',
        }),


        backward() {
            this.$router.push({ name: 'home' });
        },


        getOpacityClass( index: number ) {
            return `opacity-${index}`;
        },


        _getTranslated( name: string ) {
            return this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name) ? this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name).displayName : name
        },


        _handleResponse() {
            const profilePlayerId = this.playerProfile.player.id as number;
            const historyData : Array<any> = [[
                'Date',
                'ELO',
                { type: 'string', role: 'annotation' },
                { type: 'string', role: 'style' }
            ]];
            historyData.push([
                new Date( this.playerProfile.player.registrationDate * 1000 ), 
                1200, 
                null, 
                'point { size: 0; }'
            ]);

            let currentScore = 1200;
            this.playerProfile.eloHistory.forEach( ( item: any ) => {
                const opponent = ( item.chessGame.whitePlayer.id == profilePlayerId ) ? item.chessGame.blackPlayer.displayName : item.chessGame.whitePlayer.displayName;
                const row = [
                    new Date(item.scoreDate * 1000), 
                    item.eloRating, 
                    opponent, 
                    null
                ];
                currentScore = item.eloRating;
                historyData.push( row );
            });

            historyData.push([
                new Date(), 
                currentScore, 
                null, 
                'point { size: 0; }'
            ]);
        }
    },


    mounted() {
        this.loadPlayerProfile();
    }
});
</script>


<style scoped lang="scss">
.non-played {
  background-color: $orange;
  color: $white;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 55px;
}
</style>
