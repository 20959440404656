import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-route": "contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", null, [
      _createElementVNode("h2", { class: "paper-font-display2" }, " Шахматы "),
      _createElementVNode("p", null, " Таврия - шахматы ")
    ], -1)
  ])))
}