import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home.svg'
import _imports_1 from '@/assets/images/tavria_logo_blue.svg'


const _hoisted_1 = { class: "section-logo" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "flex-section" }
const _hoisted_4 = { class: "modal" }
const _hoisted_5 = {
  key: 1,
  class: "nav-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_PlayerInfo = _resolveComponent("PlayerInfo")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        id: "playerBtn",
        to: { name: 'home' }
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_0,
            title: "Домой"
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("a", { href: _ctx.home_url }, _cache[5] || (_cache[5] = [
        _createElementVNode("img", { src: _imports_1 }, null, -1)
      ]), 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { id: "modals" }, null, -1)),
        _createVNode(_component_Modal, {
          modelValue: _ctx.isShowLogoutModal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowLogoutModal) = $event)),
          close: _ctx.closeModalAndExit
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("p", null, " Точно что хотите выйти ? ", -1)),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModalAndExit && _ctx.closeModalAndExit(...args)))
              }, " Да "),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, " Нет ")
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "close"]),
        (_ctx.isUserLogin)
          ? (_openBlock(), _createBlock(_component_PlayerInfo, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isUserLogin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("a", {
                class: "nav-link",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showLogoutModal && _ctx.showLogoutModal(...args)))
              }, " Выход ")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}