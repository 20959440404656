<template>
    <section data-route="contact">
        <div>
            <h2 class="paper-font-display2">
                Шахматы
            </h2>
            <p>
                Таврия - шахматы
            </p>
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';


export default defineComponent({
    name: 'Contacts'
});
</script>
