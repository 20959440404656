<template>
    <section class="footer-block-fixed">
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row flex-center">
                        <router-link id="playerBtn" :to="{ name: 'contacts' }">
                            <img src="@/assets/images/tavria_logo_blue.svg" />
                        </router-link>

                        <div class="btn-chess-footer">
                            Шахматы
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';


export default defineComponent({
    name: 'ClubFooter'
});
</script>


<style scoped lang="scss">

.wrapper {
    background-color: #f2f2f2;
    padding: 24px 24px 90px 24px;
    text-shadow: 1px 1px 3px #000000;
    width: 100%;
	padding: 20px;
	margin: 0;
}
.footer-block-fixed {
	width: 100%;
    display: inline-block;
    align-self: flex-end;
}
.partner {
    display: inline;
    margin: 24px;
}
.partner img {
    height: 100px;
}
.wrapper, .wrapper a {
    color: #9ab;
    text-decoration: none;
}
h3 {
    margin-top: 24px;
    margin-bottom: 24px;
}
.btn-chess-footer {
    background-color: #007580;
    color: #f2f2f2;
    border-radius: 15px;
    height: 25px;
    padding: 5px;
    width: 150px;
    text-align: center;
    margin-left: 10px;
    line-height: 22px;
}
</style>
