<template v-if="isUserLogin">
    <section v-if="( approvalPlayerGames.length > 0 )">
            <h3>
                Заявки
            </h3>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="enemy-name">Белые</th>
                        <th class="enemy-name">Черные</th>
                        <th class="enemy-name">Статус</th>
                    </tr>
                </thead>
                <tbody class="game-row" v-for="gameApprove in approvalPlayerGames" :key="`approve_${gameApprove.id}`">
                    <tr>
                        <td class="enemy-name">
                            {{ _getTranslated( gameApprove.whitePlayer.displayName ) }}
                        </td>
                        <td class="enemy-name">
                            {{ _getTranslated( gameApprove.blackPlayer.displayName ) }}
                        </td>
                        <td class="enemy-name">
                            <div v-if="!isGameWaitingYourApprove( gameApprove )">
                                <span>
                                    Ожидает одобрения противником
                                </span>
                            </div>
                            <div v-else>
                                <button @click="approveGame( gameApprove.id )">
                                    Одобрить
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { GameApproveResponse, PlayerResponse } from "@/dto/responses";


export default defineComponent({
    name: 'GamesApprovesTable',

    data() {
        return {
            intervalID: null as number|null
        };
    },


    computed: {
        ...mapGetters({
            isUserLogin: 'auth_app/IS_USER_LOGIN',
            authenticationData: 'auth_app/AUTH_APP',
            approvalPlayerGames: 'games/PLAYER_APPROVALS_OPENED',
            robotOpponentsTranslated: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED'
        })
    },


    methods: {
        ...mapActions({
            loadApprovalPlayerGames: 'games/LOAD_PLAYER_APPROVALS',
            approvePlayerGame: 'games/APPROVE_PLAYER_GAME'
        }),


        _getTranslated( name: string ) : string {
            return this.robotOpponentsTranslated[name] ? this.robotOpponentsTranslated[name].name : name
        },


        isGameWaitingYourApprove( gameApprove: GameApproveResponse ) {
            const playerId = this.authenticationData.player.id;
            let result = false;
            /// Если текущий игрок за "белых" и ещё не одобрил заявку со своей стороны
            if ( playerId === gameApprove.whitePlayer.id && !gameApprove.isWhitePlayerApprove ) {
                result = true;
            } 
            /// Иначе, если текущий игрок за "чёрных" и ещё не одобрил заявку со своей стороны
            else if ( playerId === gameApprove.blackPlayer.id && !gameApprove.isBlackPlayerApprove ) {
                result = true;
            } 
            return result;
        },

        approveGame( approveId: number ) {
            this.approvePlayerGame( approveId );
        },


        _activePlayersChange( players: Array<PlayerResponse> ) : void {
            console.info("ACTIVE PLAYERS: ", players);
        },


        _pollingFunc() {
            this.loadApprovalPlayerGames();
            this.intervalID = setTimeout(this._pollingFunc, 5000);
        },


        _startPolling() {
            this._stopPolling();
            this._pollingFunc();
        },


        _stopPolling() {
            if ( this.  intervalID ) {
                window.clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        }
    },


    mounted() {
        this._startPolling();
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
tr.game-row:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
thead tr {
    background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
    padding: 6px;
}
.is-active-player {
    color: white;
    height: 20px;
    width: 20px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}
.is-inactive-player {
    color: white;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}
</style>
