import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    (!_ctx.withAIOpponent && _ctx.isGameActuallyStarted())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("span", { class: "player-time" }, _toDisplayString(_ctx.statusTimeStrPlayer), 513), [
            [_vShow, _ctx.playerAvailableSec]
          ]),
          _withDirectives(_createElementVNode("span", { class: "player-time" }, " Противник: " + _toDisplayString(_ctx.statusTimeStrOpponent), 513), [
            [_vShow, _ctx.opponentAvailableSec]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}