
import { RouteRecordRaw, createWebHistory, createRouter } from "vue-router";

import store from "@/store";

import Home from "@/components/Home.vue";
import Auth from "@/components/elements/Auth/Auth.vue";
import Contacts from "@/components/Partials/Contacts.vue";
import MyHistory from "@/components/elements/Player/PlayerHistory.vue";
import PlayerGame from "@/components/elements/PlayerGame/PlayerGame.vue";
import PlayerProfile from "@/components/elements/Player/PlayerProfile.vue";
import PlayerGames from "@/components/elements/PlayerGames/PlayerGames.vue";
import PlayerGameCreate from "@/components/elements/PlayerGame/PlayerGameCreate.vue";
import PlayerTournaments from "@/components/elements/PlayerTournaments/PlayerTournaments.vue";
import PlayerOpponentsRobotsTable from "@/components/elements/PlayerOpponents/PlayerOpponentsRobotsTable.vue";
import PlayerOpponentsHumansTable from "@/components/elements/PlayerOpponents/PlayerOpponentsHumansTable.vue";



const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Auth
    },
    {
        path: '/player/', 
        name: 'player',
        component: PlayerProfile
    },
    {
        path: '/game/:gameId', 
        name: 'game',
        component: PlayerGame
    },
    {
        path: '/create/:opponentId',
        name: 'game-create',
        component: PlayerGameCreate
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts
    },
    {
        path: '/ai',
        name: 'ai',
        component: PlayerOpponentsRobotsTable
    },
    {
        path: '/online',
        name: 'online',
        component: PlayerOpponentsHumansTable
    },
    {
        path: '/tournaments',
        name: 'tournaments',
        component: PlayerTournaments
    },
    {
        path: '/games',
        name: 'games',
        component: PlayerGames
    },
    {
        path: '/history',
        name: 'history',
        component: MyHistory
    }
];



const router = createRouter({
    history: createWebHistory( process.env.VUE_APP_PUBLIC_PATH ),
    routes
});



router.beforeEach( async (to, _from) => {
    document.title = 'Шахматы';

    /**
     * auth_app/IS_USER_LOGIN - может быть FALSE если у пользователя истёк токен авторизации
     * auth_app/WAS_USER_LOGOUT - метка о том что пользователь вышел из системы сам (например через Logout процедуру)
     * auth_app/UPDATE_USER_REGISTERED - обновление статуса регистрации пользователя в приложении, если он уже зарегистрирован в системе (вошёл он или нет - не имеет значения)
     */

    /// Если пользователь не залогинен (но и не выходил сам) и при этом хочет перейти на `Login`
    if ( to.name === 'login' && !store.getters['auth_app/IS_USER_LOGIN'] ) {
        const checkAuthResult = await store.dispatch( 'auth_app/CHECK_AUTH' );
        store.commit('auth_app/UPDATE_USER_REGISTERED', checkAuthResult);
        return true;  /// Перенаправляем его на `Login`
    } 
    /// Если пользователь разлогинен и хочет перейти куда-то кроме `Login`
    else if ( to.name !== 'login' && store.getters['auth_app/WAS_USER_LOGOUT'] ) {
        return { name: 'login' };  /// Перенаправляем его на `Login`
    } 
    /// Если логин пользователя истёк и хочет пойти куда-то кроме `Login`
    else if ( to.name !== 'login' && !store.getters['auth_app/IS_USER_LOGIN'] ) {
        try {
            await store.dispatch( 'auth_app/REFRESH' );
            return true;
        } catch {
            return { name: 'login' };  /// Перенаправляем его на `Login`
        }
    } 
    else return true;
});


export default router;
