import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openings, (opening) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'opening_' + opening.name
      }, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" Current opening:")),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(_toDisplayString(opening.name), 1)
        ]),
        _createElementVNode("div", null, [
          _cache[2] || (_cache[2] = _createTextVNode(" Possible variants: ")),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opening.variants, (variant, ind) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'variant_' + ind.toString()
            }, [
              _createElementVNode("span", null, _toDisplayString(variant), 1)
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}