<template>
    <section>
            <div v-for="opening in openings" :key="'opening_' + opening.name">
                <div>
                    Current opening:<br/>{{ opening.name }}
                </div>
                <div>
                    Possible variants:
                    <div v-for="(variant, ind) in opening.variants" :key="'variant_' + ind.toString()">
                        <span>
                            {{ variant }}
                        </span>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { ChessMoveResponse } from "@/dto/responses";


export default defineComponent({ 
    name: 'PlayerGameOpening',

    watch: {
        currPlayerGameMoves: {
            handler( moves: Array<ChessMoveResponse> ) {
                if ( moves && moves.length ) {
                    this.loadOpenings({ moves });
                }
            }
        }
    },


    computed: {
        ...mapGetters({
            openings: 'openings/OPENINGS',
            currPlayerGameMoves: 'games/CURR_PLAYER_GAMES_MOVES_PGN'
        })
    },


    methods: {
        ...mapActions({
            loadOpenings: 'games/LOAD_CURR_GAME_MOVES_OPENINGS'
        })
    }

});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
.wrapper {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
}
</style>
