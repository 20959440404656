
import { Module } from "vuex";
import urlJoin from 'url-join';

import { setsIntersect } from "@/common/functions";
import { axios_chess_api_client } from "@/common/axios";
import { TournamentMainPartResponse } from "@/dto/responses";



export interface TournamentState {
    _tournaments: Array<TournamentMainPartResponse>,
    _tournaments_player_ids: Set<number>,
    _curr_player_tournament: TournamentMainPartResponse
}


enum OpType {
    ADD = 'ADD',
    DELETE = 'DELETE'
}


interface UpdatePlayerParams {
    opType: OpType,
    tournamentId: number
}


const tournaments: Module<TournamentState, any> = {
    namespaced: true,

    state: {
        _tournaments: [] as Array<TournamentMainPartResponse>,
        _tournaments_player_ids: new Set() as Set<number>,
        _curr_player_tournament: {} as TournamentMainPartResponse
    },


    getters: {
        TOURNAMENTS_ALL: ( state: TournamentState ) : Array<TournamentMainPartResponse> => {
            return state._tournaments;
        },
        TOURNAMENTS_PLAYER_IDS: ( state: TournamentState ) : Set<number> => {
            return state._tournaments_player_ids;
        },
        TOURNAMENTS_PLAYER_REGISTERED: ( state: TournamentState ) : Set<TournamentMainPartResponse> => {
            const tournaments_ids = new Set( state._tournaments.map( o => o.id ) );
            return setsIntersect(tournaments_ids, state._tournaments_player_ids);
        }
    },


    actions: {
        LOAD_TOURNAMENTS_ALL: async ({ commit, rootGetters }) : Promise<Array<TournamentMainPartResponse>> => {
            const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const response = await axios_chess_api_client.get(
                urlJoin('tournament', '/'),
                { 
                    headers: { 
                        'Authorization': access_token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_TOURNAMENTS', response.data);
            return response.data;
        },

        LOAD_TOURNAMENTS_PLAYER: async ({ commit, rootGetters }) : Promise<Array<TournamentMainPartResponse>> => {
            const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const playerId = await rootGetters['auth_app/AUTH_APP'].player.id;
            const url_params = new URLSearchParams({ playerId });
            const response = await axios_chess_api_client.get(
                urlJoin('tournament', 'search', '?' + url_params.toString()),
                { 
                    headers: { 
                        'Authorization': access_token, 
                        'Content-Type': 'application/json' 
                    } 
                }
            );
            commit('SET_TOURNAMENTS_PLAYER_IDS', response.data);
            return response.data;
        },

        TOURNAMENT_PLAYER_JOIN: async ({ commit, rootGetters }, tournamentId: number) : Promise<TournamentMainPartResponse|Error> => {
            await rootGetters['auth/AUTH_STATE'].wrapRefresh();
            const authenticationData = rootGetters['auth_app/AUTH_APP'];
            commit('UPDATE_PLAYER_TOURNAMENT', { opType: OpType.ADD, tournamentId });
            try {
                const response = await axios_chess_api_client.post(
                    urlJoin('tournament', tournamentId.toString(), 'players'),
                    null,
                    { 
                        headers: { 
                            'Authorization': authenticationData.token, 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                return response.data;
            } catch {
                commit('UPDATE_PLAYER_TOURNAMENT', { opType: OpType.DELETE, tournamentId });
                return Error( 'ERR !!!' );
            }
        },

        TOURNAMENT_PLAYER_EXIT: async ({ commit }, tournamentId: number) : Promise<TournamentMainPartResponse|Error> => {
            commit('UPDATE_PLAYER_TOURNAMENT', { opType: OpType.DELETE, tournamentId });
            try {
                const response = await axios_chess_api_client.delete(
                    urlJoin('tournament', tournamentId.toString(), 'players'),
                    { 
                        headers: { 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                return response.data;
            } catch {
                commit('UPDATE_PLAYER_TOURNAMENT', { opType: OpType.ADD, tournamentId });
                return Error( 'ERR !!!' );
            }
        }
    },


    mutations: {
        SET_TOURNAMENTS: (state: TournamentState, objects: Array<TournamentMainPartResponse>) => {
            state._tournaments = objects;
        },

        SET_TOURNAMENTS_PLAYER_IDS: (state: TournamentState, objects: Array<TournamentMainPartResponse>) => {
            state._tournaments_player_ids = new Set( objects.map( o => o.id ) );
        },

        UPDATE_PLAYER_TOURNAMENT: (state: TournamentState, { opType, tournamentId } : UpdatePlayerParams) => {
            switch( opType ) {
                case OpType.ADD: {
                    state._tournaments_player_ids.add( tournamentId );
                    break;
                }
                case OpType.DELETE: {
                    state._tournaments_player_ids.delete( tournamentId );
                    break;
                }
            }
        }
    }
};


export default tournaments;
