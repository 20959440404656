
export enum USER_RANK {
    CHIEF = 'CHIEF',
    CONDUCTOR = 'CONDUCTOR',
    PASSENGER = 'PASSENGER'
}


export enum CHESS_TOURNAMENT_STATUS {
    REGISTERED = "REGISTERED",
    STARTED = "STARTED",
    PAUSED = "PAUSED",
    ENDED = "ENDED",
    ABORTED = "ABORTED"
}


export enum CHESS_GAME_STATUS {
    OPEN = "OPEN", 
    PAT = "PAT", 
    WHITEWON = "WHITEWON", 
    BLACKWON = "BLACKWON"
}


///////////////////////////////
export interface TimeDiffData {
    hours: number
    minutes: number
    seconds: number
}


export interface MsgType {
    msg: string,
    type?: string
}
