
import { createStore } from "vuex";

import auth from "@/store/auth";
import auth_app from "@/store/auth_app";
import games from "@/store/games";
import players from "@/store/players";
import tournaments from "@/store/tournaments";
import player_profile from "./player_profile";
import tournament_players from "@/store/tournament_players";



export default createStore({
    modules: {
        auth,
        auth_app,
        games,
        players,
        tournaments,
        player_profile,
        tournament_players
    }
});
