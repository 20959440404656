<template>
    <section>
            <div class="sessions-container">
                <div class="view-container">
                    <div class="profile-container">
                        <div class="flex-row">
                            <h2>
                                Онлайн
                            </h2>
                            <button @click="goBackward" class="green-btn-small">
                                Назад
                            </button>
                        </div>
                        <p>Игроки в сети</p>
                    </div>
                    <div class="blocks-container">
                        <div v-for="player in playerOpponents" :key="`player_${player.id}`"
                            class="child-block child-block-green" 
                            :class="getOpacityClass( player.id )" 
                            @click="createGameApprove( player.id )"
                        >
                            <div :class="getActivityPlayerClass( player.id )" :title="getActivityPlayerTitle( player.id )"></div>
                            <h3>
                                {{ player.displayName }}
                            </h3>
                            <div v-if="_getOpponentStatus( player.id ) === 2" class="non-played" @click="approveGame( player.id )">
                                Одобрить
                            </div>
                            <div v-if="_getOpponentStatus( player.id ) === 3" class="non-played" @click="goGame( player.id )">
                                Играть
                            </div>
                            <span v-if="_getOpponentStatus( player.id ) === 0">
                                Отправить приглашение
                            </span>
                            <span v-if="_getOpponentStatus( player.id ) === 1" class="bold">
                                Ожидает одобрения противником
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from "vue-toast-notification";
import { GameApproveResponse } from "@/dto/responses";

const $toast = useToast();


export default defineComponent({
    name: 'PlayersOpponentsHumansTable',

    data() {
        return {
            playerId: parseInt(this.$route.params.playerId as string, 10),
            intervalID: null as number|null
        };
    },


    computed: {
        ...mapGetters({
            playerOpponents: 'players/PLAYER_OPPONENTS',
            authenticationData: 'auth_app/AUTH_APP',
            fixedPlayerGames: 'games/FIXED_PLAYER_GAMES',
            approvalPlayerGames: 'games/PLAYER_APPROVALS_OPENED',
            playerOpponentsActivities: 'players/PLAYER_OPPONENTS_ACTIVITIES'
        }),

        isOpponentsExists() {
            return ( this.playerOpponents.length > 0 );
        }
    },


    methods: {
        ...mapActions({
            loadPlayerOpponents: 'players/LOAD_PLAYER_OPPONENTS',
            createPlayerGameApprove: 'games/CREATE_PLAYER_GAME_APPROVE',
            approvePlayerGame: 'games/APPROVE_PLAYER_GAME',
            loadPlayerGames: 'games/LOAD_PLAYER_GAMES',
            loadApprovalPlayerGames: 'games/LOAD_PLAYER_APPROVALS'
        }),

        goBackward() {
            this.$router.push({ name: 'home' });
        },

        getOpacityClass( playerId: number ) {
            if ( this.isPlayerOpponentActive( playerId ) ) {
                return ''
            }     
            else { 
                return 'opacity-0'
            }
        },

        getActivityPlayerClass( playerId: number ) : string {
            return this.isPlayerOpponentActive( playerId ) ? 'player-active' : 'player-inactive';
        },

        getActivityPlayerTitle( playerId: number ) : string {
            return this.isPlayerOpponentActive( playerId ) ? 'Игрок онлайн' : 'Игрок оффлайн';
        },

        isPlayerOpponentActive( playerId: number ) : boolean {
            console.log("OPPONENT ID: ", this.playerOpponentsActivities[playerId]);
            return this.playerOpponentsActivities[playerId];
        },

        isGameWaitingYourApprove( gameApprove: GameApproveResponse, opponentPlayerId: number ) : boolean {
            const playerId = this.authenticationData.player.id;
            let result = false;
            /// Если текущий игрок за "белых" и ещё не одобрил заявку со своей стороны
            if ( opponentPlayerId === gameApprove.blackPlayer.id && playerId === gameApprove.whitePlayer.id && !gameApprove.isBlackPlayerApprove ) {
                result = true;
            } 
            /// Иначе, если текущий игрок за "чёрных" и ещё не одобрил заявку со своей стороны
            else if ( opponentPlayerId === gameApprove.whitePlayer.id && playerId === gameApprove.blackPlayer.id && !gameApprove.isWhitePlayerApprove ) {
                result = true;
            } 
            return result;
        },

        isGameWaitingOpponentApprove( gameApprove: GameApproveResponse, opponentPlayerId: number ) : boolean {
            const playerId = this.authenticationData.player.id;
            let result = false;
            /// Если текущий игрок за "белых" и ещё не одобрил заявку со своей стороны
            if ( opponentPlayerId === gameApprove.blackPlayer.id && playerId === gameApprove.whitePlayer.id && gameApprove.isBlackPlayerApprove ) {
                result = true;
            } 
            /// Иначе, если текущий игрок за "чёрных" и ещё не одобрил заявку со своей стороны
            else if ( opponentPlayerId === gameApprove.whitePlayer.id && playerId === gameApprove.blackPlayer.id && gameApprove.isWhitePlayerApprove ) {
                result = true;
            } 
            return result;
        },

        /** Статус заявки если есть */
        _getOpponentStatus( opponentPlayerId: number ) : number {
            let status = 0;
            for (let index = 0; index < this.approvalPlayerGames.length; index++) {
                const element = this.approvalPlayerGames[index];
                if ( this.isGameWaitingYourApprove( element, opponentPlayerId ) ) {
                    status = 1;
                    break;
                }
                if ( this.isGameWaitingOpponentApprove( element, opponentPlayerId ) ) {
                    status = 2;
                    break;
                }
            }
            if ( status === 0 ) {
                for (let index = 0; index < this.fixedPlayerGames.length; index++) {
                    const element = this.fixedPlayerGames[index];
                    if ( !element.tournamentId && element.status === 'OPEN' ) {
                        if ( element.whitePlayer.id === opponentPlayerId && element.blackPlayer.id === this.authenticationData.player.id ) {
                            status = 3;
                            break; 
                        }
                        if ( element.blackPlayer.id === opponentPlayerId && element.whitePlayer.id === this.authenticationData.player.id ) {
                            status = 3;
                            break; 
                        }
                    }
                }  
            }
            return status;
        },

        approveGame( opponentPlayerId: number ) {
            for (let index = 0; index < this.approvalPlayerGames.length; index++) {
                const element = this.approvalPlayerGames[index];
                if ( this.isGameWaitingOpponentApprove( element, opponentPlayerId ) ) {
                    this.approvePlayerGame( element.id );
                    this.$router.push({ name: 'games' });
                    break;
                }
            }
        },

        goGame( opponentPlayerId: number ) {
            for (let index = 0; index < this.fixedPlayerGames.length; index++) {
                const element = this.fixedPlayerGames[index];
                if ( !element.tournamentId && element.status === 'OPEN' ) {
                    if ( element.whitePlayer.id === opponentPlayerId && element.blackPlayer.id === this.authenticationData.player.id ) {
                        this.$router.push({ name: 'game', params: { gameId : element.id } });
                        break; 
                    }
                    if ( element.blackPlayer.id === opponentPlayerId && element.whitePlayer.id === this.authenticationData.player.id ) {
                        this.$router.push({ name: 'game', params: { gameId : element.id } });
                        break; 
                    }
                }
            }  
        },

        createGameApprove( opponentPlayerId: number ) {
            // создадим завку если завки еще нет
            if ( this._getOpponentStatus( opponentPlayerId ) === 0) {
                $toast.success('Заявка на игру отправлена', { duration: 5000, position: 'bottom-right' });
                this.createPlayerGameApprove( opponentPlayerId );
            }
        },

        _pollingFunc() {
            this.loadPlayerGames();
            this.loadPlayerOpponents();
            this.loadApprovalPlayerGames();
            this.intervalID = setTimeout(this._pollingFunc, 5000);
        },


        _startPolling() {
            this._stopPolling();
            this._pollingFunc();
        },


        _stopPolling() {
            if ( this.  intervalID ) {
                window.clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        }
    },

    mounted() {
        this._startPolling();
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">

.player-active {
  background-color: green;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
}
.player-inactive {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
}

.non-played {
  background-color: $orange;
  color: $tavria-blue;
  padding: 10px;
  position: absolute;
  font-weight: bold;
  right: 0;
  top: 45px;
}

h3 {
  margin-left: 30px !important;
}

@media (max-width: 768px) {
  .non-played {
    top: 35px;
  }
}
</style>
