<template>
    <section class="flex-row">
            <button v-if="fixedPlayerGamesCount" class="circle-green-btn" title="Есть недоигранные партии" @click="goToPlayerGames">
                {{ fixedPlayerGamesCount }} 
            </button>

            <button v-if="playerApprovalsNeededYourCount" class="circle-btn" title="Новые игры ожидают одобрения" @click="goToPlayerGames">
                {{ playerApprovalsNeededYourCount }} 
            </button>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import store from '@/store';
import urlJoin from 'url-join';
import SockJS from 'sockjs-client';
import { Client, IMessage, Versions } from '@stomp/stompjs';

import { parseBoolFromString } from '@/common/config';
import { PlayerActivitiesResponse, PlayerResponse } from '@/dto/responses';

const IS_DEV_MODE_ENABLED = parseBoolFromString( process.env.VUE_APP_DEV_MODE );
const WS_RELATIVE_CONNECT_URL = urlJoin(process.env.VUE_APP_CHESS_API_PATH, 'ws');



function sockJsFactory( connUrl: string ) : any {
    return new SockJS( connUrl );
}


export default defineComponent({
    name: 'NavbarGamesInfo',

    data() {
        const wsClient = new Client({
            webSocketFactory: sockJsFactory.bind(null, WS_RELATIVE_CONNECT_URL),
            stompVersions: new Versions([ Versions.V1_0, Versions.V1_1, Versions.V1_2 ]),
            onConnect: () => {
                wsClient.subscribe('/topic/pushmessages', ( _messageOutput: IMessage ) => {
                    // console.debug('WS TEST MESSAGE', messageOutput.body);
                });
                wsClient.subscribe('/topic/activities', ( messageOutput: IMessage ) => {
                    const resp = ( JSON.parse( messageOutput.body ) || {} ) as PlayerActivitiesResponse;
                    console.debug("INC ACTIVE PLAYERS DATA: ", resp.data);
                    store.commit('players/SET_PLAYERS_ACTIVITIES', resp.data || []);
                });
            }
        });
        return {
            wsClient: wsClient as Client,
            intervalID: null as number|null,
            wsIntervalID: null as number|null
        }
    },


    computed: {
        ...mapGetters({
            playerGames: 'games/FIXED_PLAYER_GAMES',
            authenticationData: 'auth_app/AUTH_APP',
            playerApprovalsNeededYour: 'games/PLAYER_APPROVALS_NEEDED_YOUR'
        }),

        playerApprovalsNeededYourCount() : number {
            return this.playerApprovalsNeededYour.length;
        },

        fixedPlayerGamesCount() : number {
            return this.playerGames.length;
        }
    },


    methods: {
        ...mapActions({
            loadApprovalPlayerGames: 'games/LOAD_PLAYER_APPROVALS',
            loadPlayerGames: 'games/LOAD_PLAYER_GAMES'
        }),

        goToPlayerGames() {
            this.$router.push({ name: 'games' });
        },

        stopWsPing() {
            if ( this.wsIntervalID ) clearInterval( this.wsIntervalID );
            if ( this.wsClient.active ) this.wsClient.deactivate();
        },

        startWsPing(timeoutMs: number, routeUrl: string) : number|null {
            this.stopWsPing();
            this.wsClient.activate();
            return window.setInterval( () => {
                const player = this.authenticationData.player as PlayerResponse;
                if ( player ) {
                    const msg = { playerId: player.id, playerName: player.displayName };
                    this.wsClient.publish({ destination: routeUrl, body: JSON.stringify( msg ) });
                }
            }, timeoutMs);
        },

        _pollingFunc() {
            Promise.all([
                this.loadPlayerGames(),
                this.loadApprovalPlayerGames()
            ]).then( () => {
                this.intervalID = setTimeout(this._pollingFunc, 5000);
            });
        },

        _startPolling() {
            this._stopPolling();
            this._pollingFunc();
        },

        _stopPolling() {
            if ( this.intervalID ) {
                window.clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        }
    },


    mounted() {
        if ( !IS_DEV_MODE_ENABLED ) this.startWsPing(3000, '/ws/ping');
        this._startPolling();
    },


    unmounted() {
        this.stopWsPing();
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
@import "@/assets/css/app.scss";

.circle-btn {
    border-radius: 20px;
    width: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $tavria-blue;
    background-color: $tavria-blue;
}
.circle-btn:hover {
    background-color: white;
    color: $tavria-blue !important;
}
.circle-green-btn {
    border-radius: 20px;
    width: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $tavria-green;
    background-color: $tavria-green;
}
.circle-green-btn:hover {
    background-color: white;
    color: $tavria-green !important;
}
.flex-row {
    display: flex;
    flex-direction: row;
    border-bottom: 0 !important;
}
</style>
