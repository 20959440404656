
import { Module } from "vuex";
import urlJoin from "url-join";
import { useToast } from "vue-toast-notification";
import { AuthError, AuthErrorCode } from "@development/irs-auth-interface/dist/auth";

import { axios_chess_api_client } from "@/common/axios";
import { AuthenticationResult } from "@/dto/responses";
import { CommonLoginData, CommonRegisterData } from "@/dto/requests";
import { IS_DEMO_MODE_ENABLED, IS_DEV_MODE_ENABLED } from "@/common/globals";



class PersistAuth {
    static dumpLoginStatus(isUserLogin: boolean, fieldName: string) {
        window.localStorage.setItem( fieldName, isUserLogin.toString().toLowerCase() );
    }

    static restoreLoginStatus( fieldName: string ) : boolean {
        const sv = window.localStorage.getItem( fieldName );
        return !sv || ( sv.toLowerCase() === 'true' );
    }

    static createCookie(name: string, value: any, days: number) {
        let expires = '';
        if ( days ) {
            const date = new Date();
            date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    }

    static removeCookie( name: string ) {
        const value = '0';
        const expires = "; expires=Thu, 01 Jan 1970 00:00:01 GMT";
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}



export interface UpdateAppAuthParams {
    isUserLogin: boolean, 
    authenticationData: AuthenticationResult|null
}


export interface AuthAppState {
    isUserLogin: boolean,
    isUserRegistered: boolean,
    wasUserLogout: boolean,
    authenticationData: AuthenticationResult,
}



const $toast = useToast();

const auth_app: Module<AuthAppState, any> = {
    namespaced: true,

    state: {
        isUserLogin: false,
        isUserRegistered: false,
        wasUserLogout: !PersistAuth.restoreLoginStatus( process.env.VUE_APP_LOGIN_STATUS_PERSIST_FIELD_NAME ),
        authenticationData: {} as AuthenticationResult
    },


    getters: {
        AUTH_APP: ( state: AuthAppState ) : AuthenticationResult => {
            return state.authenticationData;
        },

        IS_USER_REGISTERED: ( state: AuthAppState ) : boolean => {
            return state.isUserRegistered;
        },

        IS_USER_LOGIN: ( state: AuthAppState ) : boolean => {
            return state.isUserLogin;
        },

        WAS_USER_LOGOUT: ( state: AuthAppState ) : boolean => {
            return state.wasUserLogout;
        }
    },


    actions: {
        REGISTER: async ({ commit, rootGetters }, payload: CommonRegisterData) : Promise<AuthenticationResult|AuthError> => {
            try {
                // const access_token = await rootGetters['auth/ACCESS_TOKEN']( payload.systemLoginData );
                let access_token = null;
                if ( IS_DEV_MODE_ENABLED || IS_DEMO_MODE_ENABLED ) {
                    access_token = await rootGetters['auth/AUTH_STATE'].userLogin( payload.systemLoginData as any );
                } else {
                    access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
                }
                const response = await axios_chess_api_client.post(
                    urlJoin('authentication', 'register', '?' + new URLSearchParams({ ...payload.appLoginData }).toString()),
                    null,
                    { 
                        headers: { 
                            'Authorization': access_token, 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: true, authenticationData: response.data });
                commit('UPDATE_USER_REGISTERED', true);
                return response.data;
            } catch ( err ) {
                const err_code = ( err as AuthError ).code;
                switch( err_code ) {
                    case AuthErrorCode.FORBIDDEN: $toast.error('Игрок с таким именем уже зарегистрирован!', { duration: 4000, position: 'bottom-right' }); break;
                    default: $toast.error('Неопознанная ошибка регистрации!', { duration: 4000, position: 'bottom-right' });
                } 
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: false });
                throw new Error( `ERROR USER AUTH REQUEST: ${err}` );
            }
        },


        LOGIN: async ({ commit, rootGetters }, payload: CommonLoginData) : Promise<AuthenticationResult|AuthError> => {
            try {
                // const access_token = await rootGetters['auth/ACCESS_TOKEN']( payload.systemLoginData );
                let access_token = null;
                if ( IS_DEV_MODE_ENABLED ) {
                    access_token = await rootGetters['auth/AUTH_STATE'].userLogin( payload.systemLoginData as any );
                } else {
                    access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
                }
                const response = await axios_chess_api_client.post(
                    urlJoin('authentication', 'login'),
                    payload.appLoginData,
                    { 
                        headers: { 
                            'Authorization': access_token, 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: true, authenticationData: response.data });
                commit('UPDATE_USER_REGISTERED', true);
                return response.data;
            } catch ( err: any ) {
                const err_code = ( err as AuthError ).code;
                switch( err_code ) {
                    case AuthErrorCode.FORBIDDEN: $toast.error('Вы не зарегистрированы в системе!', { duration: 4000, position: 'bottom-right' }); break;
                    default: $toast.error('Неопознанная ошибка авторизации!', { duration: 4000, position: 'bottom-right' });
                } 
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: false });
                throw new Error( `ERROR USER AUTH REQUEST: ${err}` );
            }
        },


        LOGOUT: async ({ commit, rootGetters }) : Promise<AuthenticationResult|Error> => {
            try {
                const authenticationData = rootGetters['auth_app/AUTH_APP'];
                const response = await axios_chess_api_client.post(
                    urlJoin('authentication', 'logout'),
                    null,
                    { 
                        headers: { 
                            'Authorization': authenticationData.token, 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: false });
                return response.data;
            } catch {
                $toast.error('Ошибка выхода!', { duration: 4000, position: 'bottom-right' });
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: false });
                throw new Error( 'ERROR USER AUTH REQUEST!' );
            }
        },


        REFRESH: async ({ commit, rootGetters }) : Promise<AuthenticationResult|Error> => {
            try {
                const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
                console.debug('CHESS ACCESS TOKEN: ', access_token);
                const response = await axios_chess_api_client.get(
                    urlJoin('authentication', 'getCredentials'),
                    { 
                        headers: { 
                            'Authorization': access_token, 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: true, authenticationData: response.data });
                return response.data;
            } catch ( err ) {
                commit('UPDATE_LOGIN_STATUS', { isUserLogin: false });
                throw new Error( `ERROR USER AUTH REQUEST: ${err}` );
            }
        },


        CHECK_AUTH: async ({ rootGetters }) : Promise<boolean|Error> => {
            try {
                const access_token = await rootGetters['auth/AUTH_STATE'].wrapRefresh();
                await axios_chess_api_client.get(
                    urlJoin('authentication', 'getCredentials'),
                    { 
                        headers: { 
                            'Authorization': access_token, 
                            'Content-Type': 'application/json' 
                        } 
                    }
                );
                return true;
            } catch ( err ) {
                return false;
            }
        }
    },


    mutations: {
        UPDATE_USER_REGISTERED: (state: AuthAppState, isUserRegistered: boolean) => {
            state.isUserRegistered = isUserRegistered;
        },

        UPDATE_LOGIN_STATUS: (state: AuthAppState, { isUserLogin, authenticationData = null }: UpdateAppAuthParams) => {
            // if ( isUserLogin && authenticationData ) PersistAuth.createCookie('AUTH_TOKEN', authenticationData.token, 3650);
            // else PersistAuth.removeCookie( 'AUTH_TOKEN' );
            PersistAuth.dumpLoginStatus(isUserLogin, process.env.VUE_APP_LOGIN_STATUS_PERSIST_FIELD_NAME);
            state.wasUserLogout = !isUserLogin;
            state.isUserLogin = isUserLogin;
            state.authenticationData = authenticationData || {} as AuthenticationResult;
        }
    }
};


export default auth_app;
