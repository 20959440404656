import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "sessions-container" }
const _hoisted_2 = { class: "view-container" }
const _hoisted_3 = { class: "profile-container" }
const _hoisted_4 = { class: "flex-row" }
const _hoisted_5 = { class: "blocks-container" }
const _hoisted_6 = { class: "table table-striped" }
const _hoisted_7 = { class: "enemy-name" }
const _hoisted_8 = { class: "num" }
const _hoisted_9 = { class: "num" }
const _hoisted_10 = { class: "num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Игровая история ", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backward())),
              class: "green-btn-small"
            }, " Назад ")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", { class: "enemy-name" }, "Противник"),
                _createElementVNode("th", { class: "num" }, "Победа"),
                _createElementVNode("th", { class: "num" }, "Ничья"),
                _createElementVNode("th", { class: "num" }, "Поражение")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerProfile.pvpStatistics, (statistic) => {
                return (_openBlock(), _createElementBlock("tr", {
                  class: "pvp",
                  key: `player_history_${statistic.opponent.id}`
                }, [
                  _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx._getTranslated( statistic.opponent.displayName )), 1),
                  _createElementVNode("td", _hoisted_8, _toDisplayString(statistic.pvpStatus.wins), 1),
                  _createElementVNode("td", _hoisted_9, _toDisplayString(statistic.pvpStatus.draws), 1),
                  _createElementVNode("td", _hoisted_10, _toDisplayString(statistic.pvpStatus.losses), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}