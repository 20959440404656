import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "history-wrapper mb-75" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { class: "num" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "num" }, " "),
            _createElementVNode("th", { class: "num" }, "Белые"),
            _createElementVNode("th", { class: "num" }, "Черные")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pairs, (pair) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: `game_pair_${pair.index}`
            }, [
              _createElementVNode("td", _hoisted_3, _toDisplayString(pair.index), 1),
              _createElementVNode("td", {
                class: _normalizeClass([pair.whiteClass, "num"])
              }, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: ($event: any) => (_ctx.moveClickW(pair, $event))
                }, _toDisplayString(pair.white), 9, _hoisted_4)
              ], 2),
              _createElementVNode("td", {
                class: _normalizeClass([pair.blackClass, "num"])
              }, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: ($event: any) => (_ctx.moveClickB(pair, $event))
                }, _toDisplayString(pair.black), 9, _hoisted_5)
              ], 2)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}