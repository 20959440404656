<template>
    <section class="block blue-block" @click="openHiddenArea">
        <h2>
            ИИ
        </h2>
        <span class="table-length">
            {{ robotOpponentsTranslatedFiltered.length }}
        </span>
        <div class="block-desc">
            Играть с искусственным интеллектом
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';


export default defineComponent({
    name: 'PlayerOpponentsRobotsTileBlock',

    data() {
        return {
            playerId: parseInt(this.$route.params.playerId as string, 10)
        };
    },


    computed: {
        ...mapGetters({
            playerGames: 'games/PLAYER_GAMES',
            robotOpponentsTranslatedFiltered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED'
        })
    },


    methods: {
        ...mapActions({
            create_robot_game: 'games/CREATE_ROBOT_GAME',
            loadRobotOpponents: 'players/LOAD_ROBOT_OPPONENTS'
        }),

        openHiddenArea() {
            this.$router.push({ name: 'ai' });
        }
    },


    mounted() {
        this.loadRobotOpponents();
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
</style>
