import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "table table-striped" }
const _hoisted_3 = { class: "enemy-name" }
const _hoisted_4 = { class: "enemy-name" }
const _hoisted_5 = { class: "enemy-name" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (( _ctx.approvalPlayerGames.length > 0 ))
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", null, " Заявки ", -1)),
        _createElementVNode("table", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { class: "enemy-name" }, "Белые"),
              _createElementVNode("th", { class: "enemy-name" }, "Черные"),
              _createElementVNode("th", { class: "enemy-name" }, "Статус")
            ])
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approvalPlayerGames, (gameApprove) => {
            return (_openBlock(), _createElementBlock("tbody", {
              class: "game-row",
              key: `approve_${gameApprove.id}`
            }, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx._getTranslated( gameApprove.whitePlayer.displayName )), 1),
                _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx._getTranslated( gameApprove.blackPlayer.displayName )), 1),
                _createElementVNode("td", _hoisted_5, [
                  (!_ctx.isGameWaitingYourApprove( gameApprove ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
                        _createElementVNode("span", null, " Ожидает одобрения противником ", -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.approveGame( gameApprove.id ))
                        }, " Одобрить ", 8, _hoisted_8)
                      ]))
                ])
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}