import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "sessions-container" }
const _hoisted_2 = { class: "view-container" }
const _hoisted_3 = { class: "profile-container" }
const _hoisted_4 = { class: "flex-row" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "blocks-container" }
const _hoisted_8 = {
  key: 0,
  class: "non-played"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "non-played"
}
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, " Турниры ", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backward())),
              class: "green-btn-small"
            }, " Назад ")
          ]),
          (_ctx.tournaments_all.length>0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Выберите из доступных турниров"))
            : _createCommentVNode("", true),
          (_ctx.tournaments_all.length===0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Нет доступных турниров"))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tournaments_all, (tournament) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "child-block child-block-orange",
              key: '_' + tournament.id
            }, [
              _createElementVNode("h3", null, _toDisplayString(tournament.name), 1),
              (_ctx._isAvailableTournamentRegistration( tournament.status ))
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (!_ctx._isAlreadyTournamentRegister( tournament.id ))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", {
                            onClick: ($event: any) => (_ctx._handleTournamentJoin(tournament.id, $event.target as HTMLInputElement))
                          }, " Подключиться ", 8, _hoisted_10)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("div", {
                            onClick: ($event: any) => (_ctx._handleTournamentExit(tournament.id, $event.target as HTMLInputElement))
                          }, " Выйти ", 8, _hoisted_12)
                        ]))
                  ]))
                : _createCommentVNode("", true),
              (_ctx._isAvailableTournamentStart( tournament.status ))
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_ctx.isMyTournament( tournament.id ))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          onClick: ($event: any) => (_ctx.goGame( tournament.id )),
                          style: {"font-weight":"600"}
                        }, " Играть ", 8, _hoisted_14))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx._getTournamentName( tournament.status )), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}