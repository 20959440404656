import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sessions-container" }
const _hoisted_2 = { class: "view-container" }
const _hoisted_3 = { class: "profile-container" }
const _hoisted_4 = { class: "profile-row" }
const _hoisted_5 = { class: "blocks-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PlayerOpponentsRobotsTileBlock = _resolveComponent("PlayerOpponentsRobotsTileBlock")!
  const _component_PlayerOpponentsHumansTileBlock = _resolveComponent("PlayerOpponentsHumansTileBlock")!
  const _component_PlayerTournamentsTileBlock = _resolveComponent("PlayerTournamentsTileBlock")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.authenticationData.player.displayName), 1),
            _createVNode(_component_router_link, { to: `/history` }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" История ")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: `/games` }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Мои игры ")
              ])),
              _: 1
            })
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Выберите тип игры и вперёд — к победе!", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_PlayerOpponentsRobotsTileBlock),
          _createVNode(_component_PlayerOpponentsHumansTileBlock),
          _createVNode(_component_PlayerTournamentsTileBlock)
        ])
      ])
    ])
  ]))
}