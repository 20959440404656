<template>
    <section>
            <div id="modals"></div>

            <Modal v-model="isShowGameEndedModal" :close="closeWinnerModal">
                <div class="modal">
                    <p>
                        {{ gameEndedText }}
                    </p>
                    <button @click="closeWinnerModal( $event )">
                        Закрыть
                    </button>
                    <button @click="closeWinnerModal($event, true)">
                        Домой
                    </button>
                </div>
            </Modal>

            <div class="wrapper">
                <div class="row chess-board-container">
                    <div class="col-lg-6 col-lg-offset-2 col-md-8">
                        <ChessGameStatus :withAIOpponent="withAIOpponent"></ChessGameStatus>
                        <div ref="chessContainerElem" class="chess-board-outer"></div>
                    </div>

                    <!-- <div style="display: flex; align-items: center;">
                        <span style="padding-right: 4px;">Статус оппонента:</span>
                        <span :class="opponentActivityClass"></span>
                    </div> -->

                    <div class="col-lg-2 col-md-4 history-new">
                        <div v-if="withAIOpponent" class="m-10">
                            <button @click="backwardStep()" class="green-btn">
                                Ход назад
                            </button>
                        </div>

                        <ChessGameOpening></ChessGameOpening>
                        <ChessGameResign></ChessGameResign>
                        <ChessGameNavigation></ChessGameNavigation>
                        <ChessHistory></ChessHistory>
                    </div>
                </div>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent, ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Position } from 'kokopu';
import { ChessBoardInstance, Chessboard, Color, COLOR, INPUT_EVENT_TYPE, MARKER_TYPE } from "cm-chessboard-ts";

import ChessHistory from "@/components/elements/PlayerGame/PlayerGameHistory.vue";
import ChessGameResign from "@/components/elements/PlayerGame/PlayerGameResign.vue";
import ChessGameStatus from "@/components/elements/PlayerGame/PlayerGameStatus.vue";
import ChessGameOpening from "@/components/elements/PlayerGame/PlayerGameOpening.vue";
import ChessGameNavigation from "@/components/elements/PlayerGame/PlayerGameNavigation.vue";

import { GameMainPartResponse, PlayerMainPartResponse, RobotPlayerResponse } from "@/dto/responses";
import { CHESS_GAME_STATUS } from "@/common/classes";

const CHECK_GAME_INTERVAL_MSEC = process.env.VUE_APP_CHECK_GAME_INTERVAL_SEC * 1000;



export default defineComponent({
    name: 'PlayerGame',

    components: { ChessGameResign, ChessGameOpening, ChessGameNavigation, ChessHistory, ChessGameStatus },

    setup() {
        const chessContainerElem = ref<HTMLElement>({} as never);
        return { chessContainerElem };
    },


    data() {
        return {
            activePlayers: {} as Record<string, any>,  /// observer: '_handleChangeActivePlayers'
            opponentActivityClass: '',
            chessGame: new Position(),
            chessBoard: {} as ChessBoardInstance,
            intervalID: null as number|null,
            currPlayerSide: null as Color|null,
            isGameEndedModalShowed: false,
            pollingProcessGameStatusVariants: new Set([CHESS_GAME_STATUS.OPEN, CHESS_GAME_STATUS.PAT])
        };
    },


    watch: {
        isCurrGameEnded( v: boolean ) {
            if ( v ) {
                console.info( "Текущая игра окончена" );
                this.chessBoard.disableMoveInput();
            } 
        }
    },


    computed: {
        ...mapGetters({
            authenticationData: 'auth_app/AUTH_APP',
            currPlayerGame: 'games/CURR_PLAYER_GAME',
            currGameMoveIndex: 'games/CURR_GAME_MOVE_INDEX',
            currPlayerGameMovesPgn: 'games/CURR_PLAYER_GAMES_MOVES_PGN',
            robotOpponentsTranslated: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
            robotPlayers: 'players/ROBOT_OPPONENTS'
        }),

        whitePlayer() : PlayerMainPartResponse {
            return this.currPlayerGame.whitePlayer || {};
        },

        blackPlayer() : PlayerMainPartResponse {
            return this.currPlayerGame.blackPlayer || {};
        },

        withAIOpponent() : boolean {
            return this.robotPlayers.some( ( robot: RobotPlayerResponse ) => robot.id === this.whitePlayer.id || robot.id === this.blackPlayer.id );
        },

        isCurrPlayerMove() : boolean {
            return this.currPlayerGame.nextPlayer.id === this.authenticationData.player.id;
        },

        isCurrGameEnded() : boolean {
            if ( this.currPlayerGame.status === CHESS_GAME_STATUS.WHITEWON || this.currPlayerGame.status === CHESS_GAME_STATUS.BLACKWON ) return true;
            else return false;
        },

        isShowGameEndedModal() : boolean {
            return ( !this.isGameEndedModalShowed && this.isCurrGameEnded );
        },

        gameEndedText() : string {
            let normCurrGameWinner = undefined;
            if ( this.currPlayerGame.status === CHESS_GAME_STATUS.WHITEWON ) normCurrGameWinner = COLOR.white;
            else if ( this.currPlayerGame.status === CHESS_GAME_STATUS.BLACKWON ) normCurrGameWinner = COLOR.black;
            if ( normCurrGameWinner ) {
                if ( this.currPlayerSide === normCurrGameWinner ) return this.currPlayerGame.isFinalStage ? "Поздравляем с победой на турнире !" : "Поздравляем с победой !";
                else return "Оппонент победил";
            } else return "";
        },
    },


    methods: {
        ...mapActions({
            loadPlayerGame: 'games/LOAD_PLAYER_GAME',
            loadRobotOpponents: 'players/LOAD_ROBOT_OPPONENTS',
            loadPlayerOpponents: 'players/LOAD_PLAYER_OPPONENTS',
            updatePlayerGameMoves: 'games/UPDATE_PLAYER_GAME_MOVES'
        }),


        ...mapMutations({
            update_curr_game_move_index: 'games/UPDATE_CURR_GAME_MOVE_INDEX'
        }),


        closeWinnerModal(event: MouseEvent, withGoHome = false) : boolean {
            event.preventDefault();
            this.isGameEndedModalShowed = true;
            if ( withGoHome ) 
                this.$router.push({ name: 'home' });
            return true;
        },


        // _handleChangeActivePlayers: function (newValue: any, _oldValue: any) {
        //     if( this.gameData ) {
        //         console.debug("CURRENT GAME ACTIVE PLAYERS: ", newValue);
        //         this._setIsActiveOpponentClass( this.gameData );
        //     }
        // },
        // _setIsActiveOpponentClass: function ( game: any ) {
        //     var firstPlayerId = game.whitePlayer.id;
        //     var secondPlayerId = game.blackPlayer.id;
        //     var currPlayerId = this.authenticationData.player.id;
        //     var opponentPlayerId = ( firstPlayerId === currPlayerId ) ? secondPlayerId : firstPlayerId;
        //     // var opponentPlayerKey = ( opponentPlayerId || "" ).toString();  /// Convert to Json-acceptable string key
        //     var isOpponentActive = this.activePlayers[opponentPlayerId] !== undefined;
        //     this.opponentActivityClass = ( isOpponentActive ) ? 'is-active-player' : 'is-inactive-player';
        // },


        next() {
            const mi = Math.min(this.currGameMoveIndex + 1, this.currPlayerGameMovesPgn.length);
            this.update_curr_game_move_index( mi );
        },


        previous() {
            const mi = Math.max(this.currGameMoveIndex - 1, 0);
            this.update_curr_game_move_index( mi );
        },


        backwardStep( backwardSteps = 1 ) {
            this.updatePlayerGameMoves({ gameId: this.currPlayerGame.id, backwardSteps }).then( ( game_data: GameMainPartResponse ) => {
                const prevMovesLength = ( this.currPlayerGame.moves ) ? this.currPlayerGame.moves.length : 0;  /// Убрать потом "костыль" с аргументом количества сделанных ходов!
                this._stopPolling();
                this._gameUpdated(game_data, prevMovesLength, false, 1);
            })
        },


        handleGameMove( event: any ) : boolean {
            const eventBoard = event.chessboard;

            const currValidPlayerSide = this.chessGame.turn();
            if ( this.currPlayerSide && this.currPlayerSide.toLowerCase() !== currValidPlayerSide.toLowerCase() )
                return false;

            if( event.type !== INPUT_EVENT_TYPE.moveInputCanceled ) {
                eventBoard.removeMarkers( MARKER_TYPE.dot );
                eventBoard.removeMarkers( MARKER_TYPE.circle );
            }
            switch ( event.type ) {
                case INPUT_EVENT_TYPE.moveInputStarted: {
                    /// Нарисовать возможные варианты ходов
                    const moves = this.chessGame.moves().filter( o => ( o.from() === event.square ) && ( o.color() === currValidPlayerSide ) );
                    if ( moves.length ) {
                        for ( const move of moves ) {
                            if ( eventBoard.getPiece( move.to() ) ) eventBoard.addMarker(MARKER_TYPE.circle, move.to());
                            else eventBoard.addMarker(MARKER_TYPE.dot, move.to());
                        }
                        return true;
                    } else return false;
                }
                case INPUT_EVENT_TYPE.validateMoveInput: {
                    const savedState = this.chessGame.fen();
                    try {
                        const isMoveLegalResult = this.chessGame.isMoveLegal(event.squareFrom, event.squareTo);
                        let pgnMove = '';
                        if ( !isMoveLegalResult ) {
                            throw Error( 'Move Error!' );
                        } else {
                            switch ( isMoveLegalResult.status ) {
                                case 'regular': {
                                    const parsedMove = isMoveLegalResult();
                                    pgnMove = this.chessGame.notation( parsedMove );
                                    break;
                                }
                                case 'promotion': {
                                    const parsedMove = isMoveLegalResult( 'q' );
                                    pgnMove = this.chessGame.notation( parsedMove );
                                    break;
                                }
                                default: break;
                            }
                        }
                        const result = this.chessGame.play( pgnMove );  /// Проверить ход и отыграть
                        this.updateGameMove( pgnMove );
                        return result;
                    } catch ( err ) {
                        console.debug("Err move description: ", err);
                        this.chessGame.fen( savedState );
                        return false;
                    }
                }
                case INPUT_EVENT_TYPE.moveInputCanceled: {
                    if ( event.legalMove ) eventBoard.disableMoveInput(); 
                    return false;
                }
                default: return false;
            }
        },


        /** 
         * Метод генерирующий запрос по получению данных игры в форме <ajaxGameGet>
         * */
        loadGame(gameId: number, isInitial: boolean) {
            const prevMovesLength = ( this.currPlayerGame.moves ) ? this.currPlayerGame.moves.length : 0;  /// Убрать потом "костыль" с аргументом количества сделанных ходов!
            this.loadPlayerGame( gameId ).then( ( game_data: GameMainPartResponse ) => {
                if ( isInitial ) {
                    this.currPlayerSide  = this._getPlayerSide( game_data );
                    this.chessBoard.enableMoveInput(this.handleGameMove, this.currPlayerSide);
                    this.chessBoard.setOrientation( this.currPlayerSide );
                }
                if ( !this.pollingProcessGameStatusVariants.has( this.currPlayerGame.status ) || this.isCurrPlayerMove ) { 
                    console.info( "Остановка цикла проверки!" );
                    this._stopPolling();
                }
                // else { 
                //     console.info( "Рестарт цикла проверки!" );
                //     this._startPolling(this.currPlayerGame.id, false);  /// Если сейчас не ход игрока, то перезапускаем цикл проверки ходов противника 
                // }
                this._gameUpdated(game_data, prevMovesLength, isInitial);
            });
        },


        /** 
         * Обновляем ход игрока и возвращаем новый FEN игры
         * */
        async updateGameMove( apiPgnMove: string ) {
            // this._stopPolling();  /// А нужно ли это тут, т.к. дублируется в `loadGame` запускаемой из `_startPolling` ?
            await this.updatePlayerGameMoves({ gameId: this.currPlayerGame.id, apiPgnMove });
            this._startPolling(this.currPlayerGame.id, false);  /// Включаем периодическую загрузку состояния игры для получения хода игрока
        },


        /** 
         * Событие по триггеру получения новых данных игры - this.gameData
         * */
        _gameUpdated(game: GameMainPartResponse, prevMovesLength: number, isInitial = false, backMoveSteps = 0) : string {
            /// А что будет если `isInitial = false`, но при этом находимся в цикле ожидания хода противника ? 
            /// Противник скопирует последний ход данного игрока, что может вызывать неоднозначное поведение
            const gameMoves = game.moves;
            if ( !isInitial && gameMoves.length > prevMovesLength ) {
                this.chessGame.play( gameMoves.at(-1)!.pgn );  /// Играем последний сделанный ход
                this.chessBoard.setPosition(this.chessGame.fen(), true);
            }
            else if ( isInitial || backMoveSteps ) {
                const savedGame = new Position();
                gameMoves.forEach( move => {
                    savedGame.play( move.pgn );
                });
                const newFen = savedGame.fen();
                if ( this.chessGame.fen() !== newFen ) {
                    this.chessGame.fen( newFen );
                    this.chessBoard.setPosition( newFen );  /// Восстанавливаем прогресс игры из API
                }
            }
            return this.chessGame.fen();
        },


        _getPlayerSide( game: GameMainPartResponse ) : Color {
            return ( this.authenticationData.player.id === game.whitePlayer.id ) ? COLOR.white : COLOR.black;
        },


        // _getbackwardMoves(moves: Array<ChessMoveResponse>, backMoveSteps: number) : Array<ChessMoveResponse> {
        //     const normBackwardSteps = Math.min(backMoveSteps, moves.length);
        //     const firstStep = moves.length - normBackwardSteps - 1;
        //     const resultMoves = [];
        //     for (let i = firstStep; i >= 0; i--) {
        //         resultMoves.unshift( moves[i] );
        //     }
        //     return resultMoves;
        // },


        _getTranslated( name: string ) {
            return this.robotOpponentsTranslated[name] ? this.robotOpponentsTranslated[name].name : name
        },


        _pollingFunc(gameId: number, isInitial: boolean, intervalSec: number) {
            gameId = gameId || this.currPlayerGame.id;
            this.loadGame(gameId, isInitial);
            this.intervalID = setTimeout(
                this._pollingFunc.bind(this, gameId, isInitial), 
                intervalSec
            );
        },


        _stopPolling() {
            if ( this.intervalID ) {
                clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        },


        _startPolling(gameId: number, isInitial: boolean, intervalSec = CHECK_GAME_INTERVAL_MSEC) {
            this._stopPolling();
            this._pollingFunc(gameId, isInitial, intervalSec);
        }
    },


    mounted() {
        Promise.all([
            this.loadRobotOpponents(),
            this.loadPlayerOpponents()
        ]).then( () => {
            this.chessBoard = new Chessboard( this.chessContainerElem, {
                position: this.chessGame.fen(),
                orientation: COLOR.white,
                responsive: true,
                style: {
                    cssClass: "black-and-white",
                    showCoordinates: true
                },
                sprite: {
                    url: "../images/chessboard-sprite.svg",
                    size: 40,
                    cache: true
                }
            });
            const gameId = parseInt(this.$route.params.gameId as string, 10);
            this._startPolling(gameId, true);  /// Это нужно если при инициализации страницы мы всё ещё ожидаем ход оппонента
        });
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
.game-data {
    text-align: center;
    font-size: larger;
    padding: 12px 0 12px 0;
}
.is-active-player {
    color: white;
    height: 20px;
    width: 20px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}
.is-inactive-player {
    color: white;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}
.chess-board-outer {
    width: 600px;
    border: 2px solid #ccc;
}
.m-10 {
    margin: 10px;
}
@media (max-width: 768px) {
    .chess-board-outer {
        width: 100vw;
    }
}
</style>
