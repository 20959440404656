<template>
    <section>
            <form class="form-horizontal" @submit="onSubmit">
                <fieldset>
                    <legend>
                        Начать игру
                    </legend>
                    <div class="form-group">
                        <label class="col-md-4 control-label" for="side">
                            Ваши фигуры
                        </label>
                        <div class="col-md-4">
                            <select ref="sideElem" id="side" name="side" class="form-control">
                                <option value="w">
                                    Белые
                                </option>
                                <option value="b">
                                    Черные
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-4 control-label" for="validate"></label>
                        <div class="col-md-4">
                            <button ref="validateElem" id="validate" name="validate" class="btn btn-primary">
                                Старт
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { GameMainPartResponse } from '@/dto/responses';


export default defineComponent({
    name: 'PlayerGameCreate',

    setup() {
        const validateElem = ref<HTMLButtonElement>({} as never);
        const sideElem = ref<HTMLInputElement>({} as never);
        return { validateElem, sideElem };
    },


    data() {
        return {
            opponentId: parseInt(this.$route.params.opponentId as string, 10)
        };
    },


    computed: {
        ...mapGetters({
            authenticationData: 'auth_app/AUTH_APP'
        }),
    },


    methods: {
        ...mapActions({
            createPlayerGameApprove: 'games/CREATE_PLAYER_GAME_APPROVE'
        }),


        onSubmit( event: any ) {
            event.preventDefault();
            this.sideElem.disabled = true;
            this.validateElem.disabled = true;
            // let whitePlayerId = this.authenticationData.player.id as number;
            // let blackPlayerId = this.opponentId;
            // if ( this.sideElem.value === 'b' ) {
            //     whitePlayerId = this.opponentId;
            //     blackPlayerId = this.authenticationData.player.id as number;
            // }
            this.createPlayerGameApprove( this.opponentId ).then( ( game_data: GameMainPartResponse ) => {
                this._handleResponse( game_data );
            });
        },


        _handleResponse( game: GameMainPartResponse ) {
            this.sideElem.disabled = false;
            this.validateElem.disabled = false;
            this.$router.push({ name: 'game', params: { id: game.id } });
        }
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
    margin: 15px;
}
paper-dialog {
    background: white;
}
</style>
