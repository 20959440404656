import urljoin from "url-join";


type KeyGetterFunc = ( k: Record<string, never> ) => string



function _isFractionalNum( num: number ) : boolean {
    return num % 1 != 0;
}



export function uuid4() : string {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}



export function setsIntersect(setOne: Set<any>, setTwo: Set<any>) : Set<any> {
    const results = new Set();
    setOne.forEach( o => {
        if( setTwo.has( o ) ) 
            results.add( o );
    });
    return results;
}


/** Return shuffled array */
export function shuffleArray( arr: Array<any> ) : Array<any> {
    return arr.map( value => ({ value, sort: Math.random() }) )
        .sort( (a, b) => a.sort - b.sort )
        .map( ({ value }) => value );
}


/** Separate ever or odd Array */
export function chunkArray(array: Array<any>, chunk_count: number = 2) : Array<Array<any>> {
    const arr_len = array.length;
    chunk_count = Math.min(chunk_count || 1, arr_len);

    const norm_chunks_size = arr_len / chunk_count;
    const is_chunk_fractional = _isFractionalNum( norm_chunks_size );
    const min_chunk_size = Math.floor( norm_chunks_size );

    const results = [];
    for (let i = 0; i < arr_len; i += min_chunk_size) {
        results.push( array.slice(i, i + min_chunk_size) );
    }
    if ( is_chunk_fractional ) {
        const last_chunk = results.pop() || [];
        results[ chunk_count - 1 ].push( ...last_chunk );
    }
    return results;
}


/** Remove element from Array of objects */
export function deleteElemByIdInplace( objs: Array<Record<string, any>>, key_name: string, key_value: any) : boolean {
    const objIndex = objs.findIndex( o => o[key_name]  === key_value );
    if( objIndex > -1 ) {
        objs.slice(objIndex, 1);
        return true;
    }
    return false;
}


/** Remove element from Array of objects */
export function updateElemByIdInplace( objs: Array<Record<string, any>>, key_name: string, key_value: any, new_object_data: any) : boolean {
    const objIndex = objs.findIndex( o => o[key_name]  === key_value );
    if( objIndex > -1 ) {
        objs.splice(objIndex, 1, { ...objs[objIndex], ...new_object_data });
        return true;
    }
    return false;
}



/** Add minutes to existing Date
 * @param { Date } dt : Date object
 * @param { number } minutes : Minutes to add
 * */
export function addMinutes(dt: Date, minutes: number) : Date {
    dt.setMinutes( dt.getMinutes() + minutes );
    return dt;
}



// noinspection JSUnusedGlobalSymbols
/** Create array from range bounds
 * @param { number } start: The start number
 * @param { number } end: The end number (if end is less than start)
 * @param { number } step: The fixed increment or decrement step (defaults to 1)
 * @return { Array<number> }
 */
export function buildArrayFromRange(start: number, end: number, step = 1) : Array<number> {
    const allNumbers = [start, end, step].every( Number.isFinite );

    if ( !allNumbers ) throw new TypeError('range() expects only finite numbers as arguments.');
    if ( step <= 0 ) throw new Error('step must be a number greater than 0.');

    if ( start > end ) step = -step;

    const length = Math.floor( Math.abs( (end - start) / step ) ) + 1;
    return Array.from( Array(length), (x, index) => start + index * step );
}



/** Checking whether `url` is absolute
 * @param url { String }
 * @return { boolean }
 * **/
export function isAbsoluteUrl( url: string ) : boolean {
    return ( url.indexOf('://') > 0 || url.indexOf('//') === 0 );
}



/** Conditionally build url from path and base
 * @param url { string }
 * @param base_url { string }
 * @return { string }
 */
export function conditionallyUrlJoin(url: string, base_url: string) : string {
    if ( !isAbsoluteUrl(url) )
        url = urljoin(base_url || "", url);
    return url;
}



// noinspection JSUnusedGlobalSymbols
/** Checking whether a string is an encoded object
 * @param s { string }
 * @return { Object|null }
 * */
export function isJsonString( s: string ) : Record<string, never>|null {
    try { return JSON.parse( s ); }
    catch { return null; }
}



export class DataUtils {

    static isNotEmpty( v: object|Array<never>|string|null|number|undefined ) : boolean {
        let result = false;
        if ( v instanceof Array ) {
            result = ( v.length !== 0 );
        } else if ( v instanceof Object ) {
            result = ( Object.keys( v ).length !== 0 );
        } else {
            switch ( typeof v ) {
                case 'string': result = ( v.length !== 0 ); break;
                case 'number': result = true; break;
                default: result = Boolean( v ); break;
            }
        }
        return result;
    }

    /** Function for grouping source Array by key of inner elements
     * @param arr Array for grouping.
     * @param keyGetter Function for getting grouping key from source array elements
     */
    static groupBy(arr: Record<string, never>[], keyGetter: KeyGetterFunc) : Map<string, never[]> {
        const result = new Map();
        arr.forEach( item => {
            const key = keyGetter( item );
            const buffered_coll = result.get( key );
            if ( !buffered_coll ) result.set(key, [item]);
            else buffered_coll.push( item );
        });
        return result;
    }
}
