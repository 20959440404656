import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/tavria_logo_blue.svg'


const _hoisted_1 = { class: "footer-block-fixed" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "row flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            id: "playerBtn",
            to: { name: 'contacts' }
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ])),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "btn-chess-footer" }, " Шахматы ", -1))
        ])
      ])
    ])
  ]))
}