<template v-if="isUserLogin">
    <section v-if="( fixedPlayerGames.length > 0 )">
        <h3>
            Недоигранные партии
        </h3>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="enemy-name">Белые</th>
                    <th class="enemy-name">Черные</th>
                    <th class="num">Ходы</th>
                    <th class="enemy-name">Последний ход</th>
                </tr>
            </thead>

            <tbody class="game-row" v-for="game in fixedPlayerGames" :key="`game_${game.id}`">
                <tr v-if="game.isOffline">
                    <td class="enemy-name">
                        <span style="color: red;">
                            Оффлайн игра (за информацией обратитесь к организатору турнира)
                        </span>
                    </td>
                </tr>
                <tr v-else>
                    <td class="enemy-name">
                        {{ _getTranslated( game.whitePlayer.displayName ) }}
                    </td>
                    <td class="enemy-name">
                        {{ _getTranslated( game.blackPlayer.displayName ) }}
                    </td>
                    <td class="num">
                        {{ game.movesCount }}
                    </td>
                    <td class="enemy-name">
                        <div v-if="game.yourTurn">
                            <span class="badge">
                                Ваш ход
                            </span>
                        </div>
                        <router-link :to="`/game/${game.id}`" class="button-small">
                            <span v-if="isFirstTournamentStage( game )">
                                Начать турнир
                            </span>
                            <span v-else-if="isNextTournamentStage( game )">
                                Продолжить турнир
                            </span>
                            <span v-else>
                                Играть
                            </span>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { GameMainPartResponse, PlayerResponse } from "@/dto/responses";


export default defineComponent({
    name: 'GamesTable',

    data() {
        return {
            activePlayers: {} as Record<string, any>,  /// observer: '_activePlayersChange'
            intervalID: null as number|null
        };
    },


    computed: {
        ...mapGetters({
            isUserLogin: 'auth_app/IS_USER_LOGIN',
            authenticationData: 'auth_app/AUTH_APP',
            robotOpponentsTranslatedFiltered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
            fixedPlayerGames: 'games/FIXED_PLAYER_GAMES'
        })
    },


    methods: {
        ...mapActions({
            loadPlayerGames: 'games/LOAD_PLAYER_GAMES'
        }),


        conditionalSelectActivityClass( game: GameMainPartResponse ) : string {
            return this._isActiveOpponent( game ) ? 'is-active-player' : 'is-inactive-player';
        },


        _getTranslated( name: string ) {
            return this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name) ? this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name).displayName : name
        },


        isFirstTournamentStage( game: GameMainPartResponse ) : boolean {
            return !!game.tournamentStage && game.tournamentStage === 1;
        },


        isNextTournamentStage( game: GameMainPartResponse ) : boolean {
            return !!game.tournamentStage && game.tournamentStage > 1;
        },


        _activePlayersChange( players: Array<PlayerResponse> ) : void {
            console.info("ACTIVE PLAYERS: ", players);
        },


        _isActiveOpponent( game: GameMainPartResponse ) : boolean {
            const firstPlayerId = game.whitePlayer.id;
            const secondPlayerId = game.blackPlayer.id;
            const opponentPlayerId = ( firstPlayerId === this.authenticationData.player.id ) ? secondPlayerId : firstPlayerId;
            const opponentPlayerKey = ( opponentPlayerId || "" ).toString();  /// Convert to Json-acceptable string key
            return ( this.activePlayers[opponentPlayerKey] !== undefined );
        },


        _pollingFunc() {
            this.loadPlayerGames();
            this.intervalID = setTimeout(this._pollingFunc, 5000);
        },


        _startPolling() {
            this._stopPolling();
            this._pollingFunc();
        },


        _stopPolling() {
            if ( this.  intervalID ) {
                window.clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        }
    },


    mounted() {
        this._startPolling();
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
tr.game-row:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
thead tr {
    background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
    padding: 6px;
}
.is-active-player {
    color: white;
    height: 20px;
    width: 20px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}
.is-inactive-player {
    color: white;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}
.badge {
    margin-right: 10px;
}
</style>
