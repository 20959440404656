<template>
    <section>  
        <div class="game-data gamer-name">
            <a :href="'/player/' + whitePlayer.id">
                {{ _getTranslated( whitePlayer.displayName ) }}
            </a>
            <span>
                против
            </span>
            <a :href="'/player/' + blackPlayer.id">
                {{ _getTranslated( blackPlayer.displayName ) }}
            </a>
        </div>

        <div class="game-data" :class="statusClass">
            <div>
                <p>{{ statusText }}</p>
            </div>
            <GameStatusTimer :withAIOpponent="withAIOpponent" @game-status-updated="_handleGameStatusStyle">
            </GameStatusTimer>
        </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { PlayerMainPartResponse } from '@/dto/responses';
import GameStatusTimer from "@/components/Partials/CurrGameTimer.vue";


export default defineComponent({
    name: 'PlayerGameStatus',

    components: { GameStatusTimer },

    props: {
        withAIOpponent: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            statusText: '',
            statusClass: ''
        };
    },


    computed: {
        ...mapGetters({
            authenticationData: 'auth_app/AUTH_APP',
            currPlayerGame: 'games/CURR_PLAYER_GAME',
            isCurrGameLoaded: 'games/IS_CURR_GAME_LOADED',
            robotOpponentsTranslatedFiltered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
        }),

        whitePlayer() : PlayerMainPartResponse {
            return this.currPlayerGame.whitePlayer || {};
        },

        blackPlayer() : PlayerMainPartResponse {
            return this.currPlayerGame.blackPlayer || {};
        }
    },


    methods: {
        ...mapActions({
            leavePlayerGame: 'games/LEAVE_PLAYER_GAME'
        }),

        _handleGameStatusStyle(statusText: string, statusClass: string = 'red-status') {
            this.statusText = statusText;
            // this.statusClass = statusClass;
            if ( statusClass ) this.statusClass = statusClass;
        },

        _getTranslated( name: string ) : string {
            return this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name) ? this.robotOpponentsTranslatedFiltered.find((r:any) => r.level === name).displayName : name
        }
    }
});
</script>


<style scoped lang="scss">
:host {
    display: block;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}
.red-status {
    display: flex;
    color: $rzd-red;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    font-size: 16px;
}
.game-data p {
    color: $tavria-green;
    font-size: 16px;
    font-weight: bold;
}
.red-status p {
    color: $rzd-red;
    font-size: 16px;
    font-weight: bold;
}
.gamer-name {
    font-size: 24px;
}
.gamer-name a {
    font-weight: bold;
    text-decoration: none;
    color: #000;
}
.player-time {
    font-size: 24px;
    color: $rzd-red;
}
@media (max-width: 768px) {
    .wrapper {
        width: 85%;
        margin: 0 auto;
    }
}
</style>
