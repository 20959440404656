
import axios, { AxiosInstance } from "axios";



const axios_api_client: AxiosInstance = axios.create({
    timeout: 1000 * 3,
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});


const axios_chess_api_client: AxiosInstance = axios.create({
    timeout: 1000 * 3,
    baseURL: process.env.VUE_APP_CHESS_API_PATH,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
});
axios_chess_api_client.defaults.headers.post['Content-Type'] = 'application/json';



export { axios_api_client, axios_chess_api_client };
