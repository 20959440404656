<template>
    <section>
      <div class="sessions-container">
        <div class="view-container">
          <div class="profile-container">
            <div class="flex-row">
              <h2>
                Турниры
              </h2>
              <button @click="backward()" class="green-btn-small">
                Назад
              </button>
            </div>
            <p v-if="tournaments_all.length>0">Выберите из доступных турниров</p>
            <p v-if="tournaments_all.length===0">Нет доступных турниров</p>
          </div>  
          <div class="blocks-container">
            <div class="child-block child-block-orange" v-for="tournament in tournaments_all" :key="'_' + tournament.id">
              <h3>
                {{ tournament.name }}
              </h3>
              <div class="non-played" v-if="_isAvailableTournamentRegistration( tournament.status )">
                  <div v-if="!_isAlreadyTournamentRegister( tournament.id )">
                      <div @click="_handleTournamentJoin(tournament.id, $event.target as HTMLInputElement)">
                          Подключиться
                      </div>
                  </div>
                  <div v-else>
                      <div @click="_handleTournamentExit(tournament.id, $event.target as HTMLInputElement)">
                          Выйти
                      </div>
                  </div>
              </div>
              <div class="non-played" v-if="_isAvailableTournamentStart( tournament.status )">
                    <span v-if="isMyTournament( tournament.id )" @click="goGame( tournament.id )" style="font-weight: 600;">
                        Играть
                    </span>
                </div>
              <span>
                {{ _getTournamentName( tournament.status ) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { GameMainPartResponse, TournamentMainPartResponse } from "@/dto/responses";


export default defineComponent({
    name: 'PlayerTournaments',

    data() {
        return {
            showHidden: false,
            tournaments: [] as Array<TournamentMainPartResponse>,
            activePlayers: {},
            chartOptions: {
                legend: 'none',
                backgroundColor: '#4E5D6C',
                pointSize: 10,
                curveType: 'function',
                series: {
                    0: { color: '#E72' }
                },
                hAxis: {
                    textStyle: { color: '#FFF' },
                    gridlines: { color: '#888' },
                    baselineColor: '#FFF'
                },
                vAxis: {
                    textStyle: { color: '#FFF' },
                    gridlines: { color: '#888' },
                    baselineColor: '#FFF'
                }
            },
            intervalID: null as number|null
        };
    },


    computed: {
        ...mapGetters({
            isUserLogin: 'auth_app/IS_USER_LOGIN',
            authenticationData: 'auth_app/AUTH_APP',
            player_profile: 'player_profile/PLAYER_PROFILE',
            tournaments_all: 'tournaments/TOURNAMENTS_ALL',
            tournaments_filtered: 'tournaments/TOURNAMENTS_PLAYER_REGISTERED',
            robots_translated_filtered: 'players/ROBOT_OPPONENTS_TRANSLATED_FILTERED',
            fixedPlayerGames: 'games/FIXED_PLAYER_GAMES'
        })
    },


    methods: {
        ...mapActions({
            loadPlayerGames: 'games/LOAD_PLAYER_GAMES',
            load_player_profile: 'player_profile/LOAD_PLAYER_PROFILE',
            load_tournaments_all: 'tournaments/LOAD_TOURNAMENTS_ALL',
            load_tournaments_player: 'tournaments/LOAD_TOURNAMENTS_PLAYER',
            player_tournament_join: 'tournaments/TOURNAMENT_PLAYER_JOIN',
            player_tournament_exit: 'tournaments/TOURNAMENT_PLAYER_EXIT'
        }),

        backward() {
            this.$router.push({ name: 'home' });
        },

        getOpacityClass(index: number) {
            return `opacity-${index}`;
        },

        /** Вы уже зарегистрированы на турнире ? */
        _isAlreadyTournamentRegister( tournamentId: number ) : boolean {
            return this.tournaments_filtered.has( tournamentId );
        },


        /** Доступен ли турнир для регистрации/выхода ? */
        _isAvailableTournamentRegistration( tournamentStatus: string ) : boolean {
            switch ( tournamentStatus ) {
                case 'REGISTERED': return true;
                default: return false;
            }
        },

        /** Турнир начался ? */
        _isAvailableTournamentStart( tournamentStatus: string ) : boolean {
            switch ( tournamentStatus ) {
                case 'STARTED': return true;
                default: return false;
            }
        },

        /** Статус турнира */
        _getTournamentName( tournamentStatus: string ) : string {
            switch ( tournamentStatus ) {
                case 'REGISTERED': return 'Регистрация участников';
                case 'ENDED': return 'Завершен';
                case 'STARTED': return 'В процессе';
                case 'PAUSED': return 'Турнир приостановлен';
                default: return '';
            }
        },

        _handleTournamentJoin(tournamentId: number, event_target: HTMLInputElement) {
            event_target.disabled = true;
            this._stopPolling();
            this.player_tournament_join( tournamentId ).finally( () => {
                event_target.disabled = false;
                this._startPolling();
            });
        },


        _handleTournamentExit(tournamentId: number, event_target: HTMLInputElement) {
            event_target.disabled = true;
            this._stopPolling();
            this.player_tournament_exit( tournamentId ).finally( () => {
                event_target.disabled = false;
                this._startPolling();
            });
        },


        _handleResponse() {
            const profilePlayerId = this.player_profile.player.id as number;
            const historyData : Array<any> = [[
                'Date',
                'ELO',
                { type: 'string', role: 'annotation' },
                { type: 'string', role: 'style' }
            ]];
            historyData.push([
                new Date( this.player_profile.player.registrationDate * 1000 ), 
                1200, 
                null, 
                'point { size: 0; }'
            ]);

            let currentScore = 1200;
            this.player_profile.eloHistory.forEach( ( item: any ) => {
                const opponent = ( item.chessGame.whitePlayer.id == profilePlayerId ) ? item.chessGame.blackPlayer.displayName : item.chessGame.whitePlayer.displayName;
                const row = [
                    new Date(item.scoreDate * 1000), 
                    item.eloRating, 
                    opponent, 
                    null
                ];
                currentScore = item.eloRating;
                historyData.push( row );
            });

            historyData.push([
                new Date(), 
                currentScore, 
                null, 
                'point { size: 0; }'
            ]);
            // this._eloHistoryData = historyData;
        },

        isMyTournament( tournamentId: number ) : boolean {
            let found = false;
            for (let index = 0; index < this.fixedPlayerGames.length; index++) {
                const element = this.fixedPlayerGames[index];
                if ( element.tournamentId === tournamentId && element.status === 'OPEN' ) {
                    found = true
                    break; 
                }
            }
            return found
        },


        isNextTournamentStage( game: GameMainPartResponse ) : boolean {
            return !!game.tournamentStage && game.tournamentStage > 1;
        },

        goGame( tournamentId: number ) {
            for (let index = 0; index < this.fixedPlayerGames.length; index++) {
                const element = this.fixedPlayerGames[index];
                if ( element.tournamentId === tournamentId && element.status === 'OPEN' ) {
                    this.$router.push({ name: 'game', params: { gameId : element.id } });
                    break; 
                }
            }  
        },

        _pollingFunc() {
            Promise.all([
                this.loadPlayerGames(),
                this.load_tournaments_all(),
                this.load_tournaments_player()
            ]);
            this.intervalID = setTimeout(this._pollingFunc, 3000);
        },


        _startPolling() {
            this._stopPolling();
            this._pollingFunc();
        },


        _stopPolling() {
            if ( this.intervalID ) {
                window.clearTimeout( this.intervalID );
                this.intervalID = null;
            }
        }
    },


    mounted() {
        this.load_player_profile();
        this._startPolling();
    },


    unmounted() {
        this._stopPolling();
    }
});
</script>


<style scoped lang="scss">
.non-played {
  background-color: $tavria-green;
  color: $orange;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 40px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .non-played {
    top: 35px;
  }
}

</style>
