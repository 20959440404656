import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickFirst && _ctx.clickFirst(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("span", {
          class: "glyphicon glyphicon-fast-backward",
          "aria-hidden": "true"
        }, null, -1)
      ])),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickPrev && _ctx.clickPrev(...args)))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("span", {
          class: "glyphicon glyphicon-backward",
          "aria-hidden": "true"
        }, null, -1)
      ])),
      _createElementVNode("span", null, _toDisplayString(_ctx.currentIndex), 1),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickNext && _ctx.clickNext(...args)))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("span", {
          class: "glyphicon glyphicon-forward",
          "aria-hidden": "true"
        }, null, -1)
      ])),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clickLast && _ctx.clickLast(...args)))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("span", {
          class: "glyphicon glyphicon-fast-forward",
          "aria-hidden": "true"
        }, null, -1)
      ]))
    ])
  ]))
}