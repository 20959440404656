<template>
    <section>
            <div class="wrapper">
                <a href="#" @click="clickFirst">
                    <span class="glyphicon glyphicon-fast-backward" aria-hidden="true"></span>
                </a>
                <a href="#" @click="clickPrev">
                    <span class="glyphicon glyphicon-backward" aria-hidden="true"></span>
                </a>
                <span>
                    {{ currentIndex }}
                </span>
                <a href="#" @click="clickNext">
                    <span class="glyphicon glyphicon-forward" aria-hidden="true"></span>
                </a>
                <a href="#" @click="clickLast">
                    <span class="glyphicon glyphicon-fast-forward" aria-hidden="true"></span>
                </a>
            </div>
    </section>
</template>


<script type="module" lang="ts">

import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";


export default defineComponent({
    name: 'PlayerGameNavigation',

    watch: {
        currGameMoveIndex: {
            handler( v: number ) {
                this._moveIndexUpdated( v );
            }
        }
    },

    data() {
        return {
            currentIndex: ""
        };
    },


    computed:{
        ...mapGetters({
            currPlayerGameMovesPgn: 'games/CURR_PLAYER_GAMES_MOVES_PGN',
            currGameMoveIndex: 'games/CURR_GAME_MOVE_INDEX'
        })
    },


    methods: {
        ...mapMutations({
            updateCurrGameMoveIndex: 'games/UPDATE_CURR_GAME_MOVE_INDEX'
        }),


        _moveIndexUpdated( moveIndex: number ) {
            this._updateRoundIndex( moveIndex );
        },


        _updateRoundIndex( moveIndex: number ) {
            if ( moveIndex === 0 ) {
                this.currentIndex = 'W01';
                return;
            }
            let ix = '';
            const actualIndex = 1 + Math.floor( ( moveIndex - 1 ) / 2 );  // Actual index is the chess round index, not the raw moves array index.
            if ( moveIndex % 2 === 0 ) {
                ix += 'B';
            } else {
                ix += 'W';
            }
            if ( actualIndex < 10 ) {
                ix += '0';
            }
            ix += actualIndex;
            this.currentIndex = ix;
        },


        clickFirst( event: MouseEvent ) : boolean {
            event.preventDefault();
            this.updateCurrGameMoveIndex( 1 );
            return false;
        },


        clickPrev( event: MouseEvent ) : boolean {
            event.preventDefault();
            if ( this.currGameMoveIndex > 1 ) {
                this.updateCurrGameMoveIndex( this.currGameMoveIndex - 1 );
            }
            return false;
        },


        clickNext( event: MouseEvent ) : boolean {
            event.preventDefault();
            if ( this.currGameMoveIndex < this.currPlayerGameMovesPgn.length ) {
                this.updateCurrGameMoveIndex( this.currGameMoveIndex + 1 );
            }
            return false;
        },


        clickLast( event: MouseEvent ) : boolean {
            event.preventDefault();
            this.updateCurrGameMoveIndex( this.currPlayerGameMovesPgn.length );
            return false;
        }
    }
});
</script>
